import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import App from './App'
import './index.css'
import { EmergencyLevels } from './utils/general'
import { SymptomsCheckerParams } from './utils/labOrdering'
import { getLabPatientInfoStore } from './utils/labPatientInfoStore'

declare global {
  interface Window {
    gtag: any
    initKylaWidget: typeof initKylaWidget
  }
}

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1,
  })
}

const initKylaWidget = (
  root: Element | null,
  {
    key,
    widgetType,
    clinicId,
    eventName,
    examRoom,
    isSymptomatic,
    symptomsCheckerParams,
    analyticsQueries,
    labOrderId,
    appointment,
    isEmployerProgram,
  }: {
    key?: string
    widgetType: string
    clinicId: string
    eventName: string
    examRoom: string
    isSymptomatic: boolean
    symptomsCheckerParams: SymptomsCheckerParams
    analyticsQueries: Record<string, string>
    labOrderId?: string
    appointment?: any
    isEmployerProgram?: boolean
  }
) => {
  if (!root) {
    return
  }

  const patientInfo = labOrderId ? getLabPatientInfoStore() : undefined

  ReactDOM.render(
    <React.StrictMode>
      <App
        key={key}
        {...{
          appointment,
          widgetType,
          clinicId,
          eventName,
          examRoom,
          isSymptomatic,
          symptomsCheckerParams: (symptomsCheckerParams
            ? symptomsCheckerParams
            : patientInfo
            ? { patientInfo }
            : {}) as any,
          analyticsQueries,
          labOrderId,
          ...(isEmployerProgram ? { isEmployerProgram } : {}),
        }}
      />
    </React.StrictMode>,
    root
  )
}

const root = document.querySelector('#root')

const widgetType = root?.getAttribute('widget-type') || ''
const clinicId = root?.getAttribute('clinic-id') || ''
const eventName = root?.getAttribute('event-name') || ''
const examRoom = root?.getAttribute('exam-room') || ''
const isSymptomatic = root?.getAttribute('is-symptomatic') !== undefined
const symptomsCheckerParams =
  root?.getAttribute('symptoms-checker-params') || {}
const analyticsQueries = root?.getAttribute('analytics-queries') || '{}'
const labOrderId = root?.getAttribute('lab-order-id') || ''
const isEmployerProgram =
  root?.getAttribute('employer-program') === 'true' || false

initKylaWidget(root, {
  widgetType,
  clinicId,
  eventName,
  examRoom,
  isSymptomatic,
  symptomsCheckerParams: symptomsCheckerParams as {
    seriousSymptoms: any[]
    emergencyLevel: EmergencyLevels
  },
  analyticsQueries: JSON.parse(analyticsQueries),
  labOrderId,
  isEmployerProgram,
})

window.initKylaWidget = initKylaWidget
