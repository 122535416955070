import { FormEvent, forwardRef, useImperativeHandle, useState } from 'react'
import {
  Box,
  Grid,
  InputLabel,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import { StripeElementChangeEvent, Token } from '@stripe/stripe-js'
import IOSSwitch from './common/IOSSwitch'
import gtag from '../utils/gtag'

export type CreditCardFormProps = {
  disabled?: boolean
  onSubmit(values: Token & { saveCard: boolean }): void
}

export type CreditCardFormActions = {
  submit(): void
}

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    marginBottom: theme.spacing(1),
  },
  ccTypeIcon: {
    height: 26,
    marginRight: theme.spacing(1),
  },
  stripeElement: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    border: '2px solid #BFD4F0',
    borderRadius: '10px',
    fontFamily: 'NeurialGrotesk',
    fontSize: '0.875rem',
    lineHeight: '1.3',
    '&:hover': {
      borderColor: theme.palette.primary.dark,
    },
    '&.focused': {
      backgroundColor: theme.palette.background.default,
      borderColor: theme.palette.primary.dark,
    },
    '&.invalid': {
      borderColor: theme.palette.error.main,
    },
    '&.Mui-disabled': {
      borderColor: '#adb2ba',
    },
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(0.5),
    fontSize: '0.75rem',
  },
}))

const CreditCardForm = forwardRef<CreditCardFormActions, CreditCardFormProps>(
  ({ disabled = false, onSubmit }, ref) => {
    const [saveCard, setSaveCard] = useState(false)

    const classes = useStyles()
    const stripe = useStripe()
    const elements = useElements()
    const [errors, setErrors] = useState({
      cardNumber: '',
      cardExpiry: '',
      cardCvc: '',
    })

    const handleElementChange = (
      event: StripeElementChangeEvent,
      field: keyof typeof errors
    ) => {
      setErrors((prev) => ({
        ...prev,
        [field]: event.error ? event.error.message : '',
      }))
    }

    const handleSubmit = async (e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault()

      if (!stripe || !elements) {
        return
      }

      const { token, error } = await stripe.createToken(
        elements.getElement(CardNumberElement)!
      )

      if (error) {
        gtag('event', 'webView_create_payment_method_failed', {
          type: error.type,
        })
      }

      if (error?.type === 'validation_error') {
        return
      }

      if (error) {
        alert(error.message)
        console.error(error.message)
        return
      }

      if (!token) {
        console.error('No token returned from Stripe')
        return
      }

      gtag('event', 'webView_payment_method_created')

      onSubmit({
        ...token,
        saveCard,
      })
    }

    useImperativeHandle(ref, () => ({
      submit: handleSubmit,
    }))

    const theme = useTheme()

    const stripeElementOptions = {
      style: {
        base: {
          fontSize: '14px',
          color: 'rgba(0, 0, 0, 0.87)',
          '::placeholder': {
            color: 'rgba(0, 0, 0, 0.54)',
          },
        },
        invalid: {
          color: theme.palette.error.main,
        },
      },
      classes: {
        base: classes.stripeElement,
        focus: 'focused',
        invalid: 'invalid',
      },
      disabled,
    }

    return (
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Card Number</InputLabel>
            <CardNumberElement
              options={{ ...stripeElementOptions, showIcon: true }}
              onChange={(e) => handleElementChange(e, 'cardNumber')}
            />
            {errors.cardNumber && (
              <Typography className={classes.errorText}>
                {errors.cardNumber}
              </Typography>
            )}
          </Grid>

          <Grid item xs={6}>
            <InputLabel className={classes.inputLabel}>
              Expiration Date
            </InputLabel>
            <CardExpiryElement
              options={stripeElementOptions}
              onChange={(e) => handleElementChange(e, 'cardExpiry')}
            />
            {errors.cardExpiry && (
              <Typography className={classes.errorText}>
                {errors.cardExpiry}
              </Typography>
            )}
          </Grid>

          <Grid item xs={6}>
            <InputLabel className={classes.inputLabel}>CVC/CVV</InputLabel>
            <CardCvcElement
              options={stripeElementOptions}
              onChange={(e) => handleElementChange(e, 'cardCvc')}
            />
            {errors.cardCvc && (
              <Typography className={classes.errorText}>
                {errors.cardCvc}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <InputLabel className={classes.inputLabel}>Save Card</InputLabel>

              <IOSSwitch
                disabled={disabled}
                checked={saveCard}
                onChange={() => setSaveCard(!saveCard)}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    )
  }
)

export default CreditCardForm
