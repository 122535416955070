import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import { grey } from '@mui/material/colors'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoIcon from '@mui/icons-material/Info'

import AuthLayout from '../../components/layouts/AuthLayout'
import FooterButtons from '../../components/FooterButtons'
import { IndicatedPaymentMethod } from '../../store/appointment/types'
import appointmentActions from '../../store/appointment/actions'
import {
  getAppointmentProductId,
  getCopayStatus,
  getFetchingCopayPossibility,
  getSourceWidgetType,
  useProductById,
} from '../../store/appointment/selectors'
import { getLoadingState } from '../../utils/types'
import Centered from '../../components/common/Centered'
import {
  getUserInsuranceIsEligible,
  getUserProfile,
} from '../../store/auth/selectors'
import { RoutePath } from '../../routes'
import ThemedButton from '../../components/common/ThemedButton'
import { TelemedicineWidgetTypes } from '../../utils/appointment/general'
import { insuranceActions } from '../../store/insurance/actions'
import { getFetchingPatientOfficeAllyInfo } from '../../store/insurance/selectors'
import theme from '../../utils/theme'
import gtag from '../../utils/gtag'
import DetailedInsuranceInfoCard from './DetailedInsuranceInfoCard'
import { useIsLabOrderingFlow } from '../../hooks/labOrdering/useIsLabOrderingFlow'
import { setConsultationExistence } from '../../store/testOrdering'
import {
  getIsEmployerProgram,
  getJoinCorporateProgramInfo,
} from '../../store/testOrdering/selectors'

const useStyles = makeStyles(
  (theme) => ({
    content: {
      display: 'flex',
      overflow: 'auto',
      marginBottom: theme.spacing(1.5),
    },
    label: {
      ...theme.typography.body2,
      color: grey[600],
      textAlign: 'center',
      marginBottom: theme.spacing(1.5),
    },
    insuranceCard: {
      border: `2px solid ${theme.palette.primary.light}`,
      padding: theme.spacing(1.5),
      borderRadius: '20px',
    },
    text: {
      fontFamily: 'NeurialGrotesk',
      color: theme.palette.primary.dark,
    },
    insuranceButton: {
      width: 'fit-content',
      fontWeight: '400',
      marginTop: theme.spacing(1),
    },
    accordion: {
      border: `2px solid ${theme.palette.primary.light}`,
      borderRadius: '20px !important',
      boxShadow: 'none',
      '&::before': {
        display: 'none',
      },
      margin: '0 !important',
    },
    accordionSummary: {
      minHeight: '0 !important',
      '& .MuiAccordionSummary-content': {
        margin: '12px 0 !important',
      },
    },
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
  }),
  { name: 'ChoosePaymentMethod' }
)

const ChoosePaymentMethod: React.FC = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()

  const { state = {} } = useLocation<{ hideBackButton?: boolean }>()

  const contentBoxRef = useRef<null | HTMLDivElement>(null)
  const selfPayButtonRef = useRef<null | HTMLButtonElement>(null)

  const [expanded, setExpanded] = React.useState(false)

  const isLabOrderingFlow = useIsLabOrderingFlow()

  const isEmployerProgram = useSelector(getIsEmployerProgram)

  const sourceWidgetType = useSelector(getSourceWidgetType)

  const isVirtual = useMemo(
    () => TelemedicineWidgetTypes.includes(sourceWidgetType),
    [sourceWidgetType]
  )

  const appointmentProductId = useSelector(getAppointmentProductId)
  const { loading: consultationProductLoading } = useProductById(
    appointmentProductId
  )

  const profile = useSelector(getUserProfile)

  const personalInsuranceEligibility = useSelector(getUserInsuranceIsEligible)

  const hasValidInsurance =
    profile?.employeeInsuranceInfo?.insuranceUnverifiable ||
    personalInsuranceEligibility

  const personalCopayStatus = useSelector(getCopayStatus)
  const fetchingCopayStatus = useSelector(getFetchingCopayPossibility)

  const companyCopayDeductibleAvailable =
    profile?.employeeInsuranceInfo?.copay ||
    profile?.employeeInsuranceInfo?.deductible

  const fetchingPatientOfficeAllyInfo = useSelector(
    getFetchingPatientOfficeAllyInfo
  )

  const joinCorporateProgramInfo = useSelector(getJoinCorporateProgramInfo)

  const isPartOfCorporateProgram =
    joinCorporateProgramInfo?.id || profile.companyId

  const loadProduct = useCallback(() => {
    dispatch(appointmentActions.getProductById.request(appointmentProductId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentProductId])

  useEffect(loadProduct, [loadProduct])

  const getPatientOfficeAllyInfo = useCallback(() => {
    if (hasValidInsurance) {
      dispatch(insuranceActions.getPatientOfficeAlly.request())
    }
  }, [hasValidInsurance])

  useEffect(getPatientOfficeAllyInfo, [getPatientOfficeAllyInfo])

  useEffect(() => {
    if (expanded && contentBoxRef?.current && selfPayButtonRef?.current) {
      setTimeout(() => {
        selfPayButtonRef.current!.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        })
      }, 300)
    }
  }, [contentBoxRef?.current?.scrollHeight])

  const loadCopayStatus = () => {
    dispatch(
      appointmentActions.getCopayStatus.request({
        isVirtual,
        isEmployerProgram,
      })
    )
  }

  useEffect(loadCopayStatus, [])

  const handleBackClick = () => {
    dispatch(
      appointmentActions.setSelectedPaymentMethod(IndicatedPaymentMethod.cash)
    )

    dispatch(setConsultationExistence(false))

    history.goBack()
  }

  const handleSubmit = () => {
    if (
      personalCopayStatus ||
      (companyCopayDeductibleAvailable && isEmployerProgram)
    ) {
      history.push(RoutePath.copayPayment)
    } else {
      dispatch(
        appointmentActions.setSelectedPaymentMethod(
          IndicatedPaymentMethod.insurance
        )
      )

      history.push(
        isLabOrderingFlow && !isPartOfCorporateProgram
          ? RoutePath.appointmentTime
          : RoutePath.choosePaymentCard
      )
    }
  }

  const handlePayWithCash = useCallback(() => {
    gtag('event', 'click_no_insurance_selfpay_option')

    dispatch(
      appointmentActions.setSelectedPaymentMethod(IndicatedPaymentMethod.cash)
    )

    history.push(
      isLabOrderingFlow
        ? isPartOfCorporateProgram
          ? RoutePath.choosePaymentCard
          : RoutePath.chooseConsultationExistence
        : RoutePath.createAppointment
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, isLabOrderingFlow, isPartOfCorporateProgram])

  const {
    pending: fetchingProductPending,
    success: fetchingProductSuccess,
    failure: fetchingProductFailure,
  } = getLoadingState(consultationProductLoading)

  const {
    pending: fetchingCopayPending,
    success: fetchingCopaySuccess,
    failure: fetchingCopayFailure,
  } = getLoadingState(fetchingCopayStatus)

  const {
    pending: fetchingOfficeAllyInfoPending,
    success: fetchingOfficeAllyInfoSuccess,
    failure: fetchingOfficeAllyInfoFailure,
  } = getLoadingState(fetchingPatientOfficeAllyInfo)

  return (
    <AuthLayout
      title="Insurance information"
      bottomActions={
        <FooterButtons
          nextOnly={state.hideBackButton}
          nextButtonLabel="Next"
          backButtonLabel="Back"
          onNextButtonClick={handleSubmit}
          onBackButtonClick={handleBackClick}
          disableNext={
            !hasValidInsurance ||
            !fetchingProductSuccess ||
            !fetchingCopaySuccess
          }
        />
      }
      contentClass={classes.content}
      contentProps={{ ref: contentBoxRef }}
    >
      <Box display="flex" flex={1} flexDirection="column" pt={3} gap={2}>
        {fetchingProductSuccess &&
        fetchingCopaySuccess &&
        (fetchingOfficeAllyInfoSuccess ||
          !hasValidInsurance ||
          isEmployerProgram) ? (
          <>
            <Box display="flex" gap={1}>
              <InfoIcon color="primary" />
              <Typography color={theme.palette.primary.dark}>
                Urgent care values only. Service coverage not guaranteed.
              </Typography>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              className={classes.insuranceCard}
            >
              <DetailedInsuranceInfoCard />

              <ThemedButton
                size="small"
                color="primary"
                variant={!hasValidInsurance ? 'outlined' : 'contained'}
                className={classes.insuranceButton}
                onClick={() => history.push(RoutePath.insuranceInfo)}
              >
                {profile?.insuranceName ? 'Edit' : 'Add'}
              </ThemedButton>
            </Box>

            <Accordion
              className={classes.accordion}
              expanded={expanded}
              onChange={() => {
                if (!expanded) {
                  gtag('event', 'click_no_insurance_expanded')
                }

                setExpanded(!expanded)
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color="primary" />}
                className={classes.accordionSummary}
              >
                <Typography variant="body1" className={classes.text}>
                  No Insurance?
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Typography variant="body1" className={classes.text}>
                  {`If you don’t have or don’t want use your insurance ${
                    isLabOrderingFlow ? '' : 'for this consultation '
                  }you can use cash.`}
                </Typography>

                <ThemedButton
                  ref={selfPayButtonRef}
                  size="small"
                  color="primary"
                  variant="outlined"
                  className={classes.insuranceButton}
                  onClick={handlePayWithCash}
                >
                  Proceed With Self-pay Option
                </ThemedButton>
              </AccordionDetails>
            </Accordion>
          </>
        ) : (
          <Centered>
            {(fetchingProductPending ||
              fetchingCopayPending ||
              fetchingOfficeAllyInfoPending) && <CircularProgress />}
            {(fetchingProductFailure ||
              fetchingCopayFailure ||
              fetchingOfficeAllyInfoFailure) && (
              <Box display="flex" flexDirection="column">
                <Typography gutterBottom>
                  Something went wrong, please try again.
                </Typography>
                <Button
                  onClick={() => {
                    loadProduct()
                    loadCopayStatus()
                    getPatientOfficeAllyInfo()
                  }}
                >
                  Reload
                </Button>
              </Box>
            )}
          </Centered>
        )}
      </Box>
    </AuthLayout>
  )
}

export default ChoosePaymentMethod
