import { useCallback } from 'react'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { RoutePath } from '../../routes'
import appointmentActions from '../../store/appointment/actions'
import { IndicatedPaymentMethod } from '../../store/appointment/types'
import { useIsLabOrderingFlow } from 'src/hooks/labOrdering/useIsLabOrderingFlow'
import FooterButtons from '../../components/FooterButtons'
import AuthLayout from '../../components/layouts/AuthLayout'
import { SUPPORT_PHONE_NUMBER } from '../../utils/general'
import PhoneNumberButton from '../../components/common/PhoneNumberButton'
import { getJoinCorporateProgramInfo } from '../../store/testOrdering/selectors'
import { getUserProfile } from '../../store/auth/selectors'

const useStyles = makeStyles(
  (theme) => ({
    text: {
      fontSize: '1.125rem',
      fontFamily: 'NeurialGrotesk',
      color: theme.palette.primary.dark,
      fontWeight: 700,
      lineHeight: '130%',
      marginBottom: theme.spacing(2),
    },
  }),
  { name: 'InsuranceAutoVerificationFailed' }
)

const InsuranceAutoVerificationFailed = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const isLabOrderingFlow = useIsLabOrderingFlow()

  const joinCorporateProgramInfo = useSelector(getJoinCorporateProgramInfo)

  const profile = useSelector(getUserProfile)

  const isPartOfCorporateProgram =
    joinCorporateProgramInfo?.id || profile.companyId

  const handleGoNext = useCallback(() => {
    dispatch(
      appointmentActions.setSelectedPaymentMethod(IndicatedPaymentMethod.cash)
    )

    history.push(
      isLabOrderingFlow
        ? isPartOfCorporateProgram
          ? RoutePath.choosePaymentCard
          : RoutePath.chooseConsultationExistence
        : RoutePath.createAppointment
    )
  }, [dispatch, history, isLabOrderingFlow, isPartOfCorporateProgram])

  const handleGoBack = useCallback(() => {
    history.push(RoutePath.insuranceInfo)
  }, [history])

  return (
    <AuthLayout
      title="Insurance Verification"
      bottomActions={
        <FooterButtons
          nextButtonLabel="Self Pay"
          onNextButtonClick={handleGoNext}
          onBackButtonClick={handleGoBack}
        />
      }
    >
      <Typography className={classes.text}>
        Your insurance cannot be verified automatically, please call us in
        business hours for manual verification.
      </Typography>

      <PhoneNumberButton fullWidth phoneNumber={SUPPORT_PHONE_NUMBER} />
    </AuthLayout>
  )
}

export default InsuranceAutoVerificationFailed
