import React, { createContext, useEffect } from 'react'
import { ThemeProvider, StyledEngineProvider } from '@mui/material'
import { MemoryRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { isEmpty } from 'lodash'

import muiTheme from 'src/utils/theme'
import { resetPasswordRoutes } from './routes'
import Body from './Body'
import { store } from './store'
import {
  AppFlows,
  WIDGET_TYPE_APP_FLOWS_MAP,
  WIDGET_TYPE_INITIAL_ROUTES_MAP,
} from './utils/general'
import FontsLoader from './components/FontsLoader'
import { analyticsActions } from './store/analytics/actions'
import { SymptomsCheckerParams } from './utils/labOrdering'
import appointmentActions from './store/appointment/actions'
import { TelemedicineWidgetTypes } from './utils/appointment/general'
import { setIsEmployerProgram } from './store/testOrdering'

type AppProps = {
  widgetType: string
  clinicId: string
  examRoom: string
  eventName: string
  isSymptomatic: boolean
  symptomsCheckerParams: SymptomsCheckerParams
  analyticsQueries: Record<string, string>
  labOrderId?: string
  appointment?: any
  isEmployerProgram?: boolean
}

export const AppFlowContext = createContext(AppFlows.appFlow)

const App: React.FC<AppProps> = ({
  widgetType,
  clinicId,
  eventName,
  examRoom,
  isSymptomatic,
  symptomsCheckerParams,
  analyticsQueries,
  labOrderId,
  appointment,
  isEmployerProgram = false,
}) => {
  const initialRoutes = WIDGET_TYPE_INITIAL_ROUTES_MAP[widgetType as string]

  const routes = initialRoutes || resetPasswordRoutes

  useEffect(() => {
    if (!isEmpty(analyticsQueries)) {
      store.dispatch(
        analyticsActions.storeAnalyticsQueries({ queries: analyticsQueries })
      )
    }
  }, [JSON.stringify(analyticsQueries)])

  useEffect(() => {
    if (appointment) {
      store.dispatch(
        appointmentActions.preselectAppointment({
          appointment: {
            ...appointment.appointment,
            isTelemedicineClinic: TelemedicineWidgetTypes.includes(
              appointment.sourceWidgetType
            ),
          },
        })
      )
    }

    if (isEmployerProgram) {
      store.dispatch(setIsEmployerProgram(isEmployerProgram))
    }
  }, [appointment, isEmployerProgram])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Provider store={store}>
            <MemoryRouter
              initialEntries={routes}
              initialIndex={symptomsCheckerParams?.emergencyLevel ? 1 : 0}
            >
              <AppFlowContext.Provider
                value={WIDGET_TYPE_APP_FLOWS_MAP[widgetType as string]}
              >
                <FontsLoader />
                <Body
                  labOrderId={labOrderId}
                  eventName={eventName}
                  clinicId={clinicId}
                  examRoom={examRoom}
                  widgetType={widgetType}
                  isSymptomatic={isSymptomatic}
                  symptomsCheckerParams={symptomsCheckerParams}
                />
              </AppFlowContext.Provider>
            </MemoryRouter>
          </Provider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App
