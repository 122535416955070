import React, { useCallback, useImperativeHandle } from 'react'
import { useForm } from 'react-hook-form'
import { Grid, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import ThemedTextField from '../common/ThemedTextField'

const useStyles = makeStyles(
  (theme: Theme) => ({
    formInput: {
      width: '100%',
      borderRadius: '10px',
      marginTop: theme.spacing(1.25),
    },
    iconButtonRoot: {
      color: theme.palette.primary.dark,
    },
  }),
  { name: 'ForgotPasswordForm' }
)
export type ForgotPasswordFormValues = {
  email: string
}

interface ForgotPasswordFormProps {
  onSubmit(data: ForgotPasswordFormValues): void
  disabled: boolean
}

const ForgotPasswordForm = React.forwardRef<any, ForgotPasswordFormProps>(
  ({ onSubmit, disabled }, ref: React.Ref<any>) => {
    const classes = useStyles()

    const { register, handleSubmit, errors } = useForm<
      ForgotPasswordFormValues
    >({
      mode: 'onChange',
      shouldFocusError: false,
    })

    const handleNewAccountSubmit = useCallback(
      (values: ForgotPasswordFormValues) => {
        onSubmit(values)
      },
      [onSubmit]
    )

    useImperativeHandle(ref, () => ({
      submit: handleSubmit(handleNewAccountSubmit),
    }))

    return (
      <Grid>
        <ThemedTextField
          name="email"
          disabled={disabled}
          autoComplete="off"
          inputRef={register({
            required: true,
            pattern: {
              value: /^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,4}$/i,
              message: 'Invalid email address',
            },
          })}
          classes={{ input: classes.formInput }}
          color="primary"
          placeholder="Email"
          error={Boolean(errors.email)}
        />
      </Grid>
    )
  }
)

export default ForgotPasswordForm
