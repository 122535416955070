import { useCallback, useEffect, useRef, useMemo } from 'react'
import { Typography, Box, Stack, Divider } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isNil } from 'lodash'

import AuthLayout from '../../components/layouts/AuthLayout'
import FooterButtons from '../../components/FooterButtons'
import CreditCardForm, {
  CreditCardFormActions,
} from '../../components/CreditCardForm'
import {
  getAppointmentProductId,
  getCopayInfoSelector,
  getCreatePaymentTokenSubmitting,
  getPayForAppointmentSubmitting,
  getSavedPaymentId,
  getSavePaymentMethodSubmitting,
  getSelectedPaymentMethod,
  getStripePublicKey,
  getStripePublicKeyLoading,
  useProductById,
} from '../../store/appointment/selectors'
import appointmentActions from '../../store/appointment/actions'
import { getLoadingState } from '../../utils/types'
import { IndicatedPaymentMethod } from '../../store/appointment/types'
import { RoutePath } from '../../routes'
import useLoadingSuccess from '../../hooks/useLoadingSuccess'
import useLoadingFailureAlert from '../../hooks/useLoadingFailureAlert'
import useLoadingFailure from '../../hooks/useLoadingFailure'
import gtag from '../../utils/gtag'
import { extractErrorMessage } from '../../utils/errors'
import { useIsLabOrderingFlow } from '../../hooks/labOrdering/useIsLabOrderingFlow'
import { useLabOrderData } from '../../hooks/labOrdering/useLabOrderData'
import {
  getConsultationExistence,
  getIsEmployerProgram,
  getPayingForLabOrder,
} from '../../store/testOrdering/selectors'
import { payForLabOrderAction } from '../../store/testOrdering'
import { useLabOrderDraftCreate } from '../../hooks/labOrdering/useLabOrderDraftCreate'
import {
  trackAddPaymentInfo,
  trackLabPaymentConfirmation,
} from '../../utils/analytics'
import { LabOrderPayInsuranceOption } from '../../components/labOrdering/LabOrderPayInsuranceOption'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, Token } from '@stripe/stripe-js'
import { getUserProfile } from '../../store/auth/selectors'

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  scrollContainer: {
    flex: 1,
    overflow: 'auto',
  },
  hint: {
    color: theme.palette.grey[500],
  },
  switchContainer: {
    marginTop: theme.spacing(2),
  },
  switchLabel: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  footerButtons: {
    paddingTop: theme.spacing(2),
  },
}))

const AddPaymentMethod = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()

  const isLabOrderingFlow = useIsLabOrderingFlow()

  const isEmployerProgram = useSelector(getIsEmployerProgram)

  const consultationExistence = useSelector(getConsultationExistence)

  const { order } = useLabOrderData()

  const creditCardForm = useRef<CreditCardFormActions>(null)

  const { labOrderDraft } = useLabOrderDraftCreate()

  const paymentMethodId = useSelector(getSavedPaymentId)

  const payingForLabOrder = useSelector(getPayingForLabOrder)

  const selectedPaymentMethod = useSelector(getSelectedPaymentMethod)
  const isCashPayment = selectedPaymentMethod === IndicatedPaymentMethod.cash

  const savePaymentMethodSubmitting = useSelector(
    getSavePaymentMethodSubmitting
  )
  const appointmentProductId = useSelector(getAppointmentProductId)
  const { product: consultationProduct } = useProductById(appointmentProductId)

  const stripePublicKeyLoading = useSelector(getStripePublicKeyLoading)
  const createPaymentTokenSubmitting = useSelector(
    getCreatePaymentTokenSubmitting
  )

  const payForAppointmentSubmitting = useSelector(
    getPayForAppointmentSubmitting
  )

  const createPaymentTokenSubmittingState = getLoadingState(
    createPaymentTokenSubmitting
  )
  const stripePublicKeyLoadingState = getLoadingState(stripePublicKeyLoading)

  const savePaymentMethodSubmittingState = getLoadingState(
    savePaymentMethodSubmitting
  )

  const payForAppointmentSubmittingState = getLoadingState(
    payForAppointmentSubmitting
  )

  const payingForLabOrderState = getLoadingState(payingForLabOrder)

  const copayInfo = useSelector(getCopayInfoSelector)

  const profile = useSelector(getUserProfile)

  const loadStripePublicKey = useCallback(() => {
    dispatch(appointmentActions.getStripePublicKey.request())
  }, [dispatch])

  useEffect(loadStripePublicKey, [loadStripePublicKey])

  const triggerSubmit = useCallback(() => {
    creditCardForm.current?.submit?.()
  }, [])

  const handleSubmit = useCallback((data: Token & { saveCard: boolean }) => {
    dispatch(appointmentActions.savePaymentMethod.request(data))
  }, [])

  const handleBackClick = useCallback(() => {
    history.goBack()
  }, [history])

  const handleSavePaymentMethodForLabOrder = useCallback(() => {
    dispatch(
      payForLabOrderAction({
        customerOrderId: labOrderDraft!.id,
        paymentMethodId: paymentMethodId!,
      })
    )
  }, [dispatch, paymentMethodId, labOrderDraft])

  const handleSavePaymentMethodForConsultation = useCallback(() => {
    if ((isCashPayment || copayInfo) && !isLabOrderingFlow) {
      dispatch(appointmentActions.payForAppointment.request())
    } else {
      history.push(RoutePath.createAppointment)
    }
  }, [dispatch, history, isCashPayment, copayInfo, isLabOrderingFlow])

  useLoadingSuccess(savePaymentMethodSubmitting, () => {
    gtag('event', 'webView_payment_method_saved')

    if (consultationExistence) {
      handleSavePaymentMethodForConsultation()
    } else {
      handleSavePaymentMethodForLabOrder()
    }

    trackAddPaymentInfo()
  })

  useLoadingFailure(savePaymentMethodSubmitting, ({ error }) => {
    gtag('event', 'webView_save_payment_method_failed', {
      message: extractErrorMessage(error),
    })
  })

  useLoadingFailureAlert(savePaymentMethodSubmitting)

  useLoadingFailure(payForAppointmentSubmitting, ({ error }) => {
    gtag('event', 'webView_attach_payment_method_failed', {
      message: extractErrorMessage(error),
    })
  })

  useLoadingSuccess(payForAppointmentSubmitting, () => {
    history.replace(RoutePath.paymentSuccessful)
  })

  useLoadingFailureAlert(payForAppointmentSubmitting)

  useLoadingSuccess(payingForLabOrder, () => {
    history.replace(RoutePath.labOrderPaymentSuccessful)

    if (order) {
      trackLabPaymentConfirmation({
        labId: order.id,
        price:
          isEmployerProgram && order.employerProgramPrice
            ? order.employerProgramPrice
            : order.price,
      })
    }
  })

  useLoadingFailureAlert(payingForLabOrder)

  const pageIsLoading = useMemo(
    () =>
      stripePublicKeyLoadingState.pending ||
      createPaymentTokenSubmittingState.pending ||
      savePaymentMethodSubmittingState.pending ||
      payForAppointmentSubmittingState.pending ||
      payingForLabOrderState.pending,
    [
      stripePublicKeyLoadingState,
      createPaymentTokenSubmittingState,
      savePaymentMethodSubmittingState,
      payForAppointmentSubmittingState,
      payingForLabOrderState,
    ]
  )

  const stripePublicKey = useSelector(getStripePublicKey)
  const stripePromise = useMemo(
    () => (stripePublicKey ? loadStripe(stripePublicKey) : null),
    [stripePublicKey]
  )

  const employerProgramPrice = isCashPayment
    ? order?.employerProgramPrice
    : isNil(copayInfo?.paymentAmount)
    ? !isNil(profile.employeeInsuranceInfo?.deductible) ||
      !isNil(profile.employeeInsuranceInfo?.copay)
      ? (profile.employeeInsuranceInfo.copay || 0) +
        (profile.employeeInsuranceInfo.deductible || 0)
      : order?.employerProgramPrice
    : copayInfo!.paymentAmount

  const amountToPay = useMemo(() => {
    if (isLabOrderingFlow && order && !consultationExistence) {
      return isEmployerProgram ? employerProgramPrice : order.price
    } else if (isCashPayment || copayInfo?.paymentAmount) {
      return isCashPayment
        ? consultationProduct?.priceDecimal
        : copayInfo?.paymentAmount
    }

    return 0
  }, [
    copayInfo,
    consultationProduct,
    order,
    isLabOrderingFlow,
    consultationExistence,
    isCashPayment,
    employerProgramPrice,
  ])

  return (
    <AuthLayout
      title={
        isNil(amountToPay) ? 'Add Card Information' : `To Pay: $${amountToPay}`
      }
      bottomActions={
        <FooterButtons
          classes={{
            root: classes.footerButtons,
          }}
          nextButtonLabel="Next"
          backButtonLabel="Back"
          onNextButtonClick={triggerSubmit}
          onBackButtonClick={handleBackClick}
          disableNext={pageIsLoading}
          disableBack={pageIsLoading}
          loadingNext={
            createPaymentTokenSubmittingState.pending ||
            savePaymentMethodSubmittingState.pending ||
            payForAppointmentSubmittingState.pending ||
            payingForLabOrderState.pending
          }
        />
      }
      contentClass={classes.content}
    >
      <Box className={classes.scrollContainer}>
        <Stack spacing={1}>
          <Typography color="primary.dark">
            {isCashPayment
              ? ''
              : 'Credit card information is required for any deductible, copay or cancellation fees that may apply.'}
          </Typography>

          <Stack spacing={2}>
            {stripePublicKeyLoadingState.success && stripePromise && (
              <Elements stripe={stripePromise}>
                <CreditCardForm
                  disabled={pageIsLoading}
                  ref={creditCardForm}
                  onSubmit={handleSubmit}
                />
              </Elements>
            )}

            {!isLabOrderingFlow && (
              <Box>
                <Typography
                  className={classes.hint}
                  variant="body2"
                  color="primary"
                >
                  {isCashPayment
                    ? 'You have 15 minutes to pay for a service or your appointment will be canceled.'
                    : 'Please Note: We need this card on file in case you do not use the service based on Kyla policy.'}
                </Typography>
              </Box>
            )}

            <Box>
              <Divider />
            </Box>

            {isCashPayment && isLabOrderingFlow && (
              <Box>
                <LabOrderPayInsuranceOption />
              </Box>
            )}
          </Stack>
        </Stack>
      </Box>
    </AuthLayout>
  )
}

export default AddPaymentMethod
