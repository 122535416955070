import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import {
  Alert,
  AlertTitle,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment'

import SearchInsuranceCompanies from 'src/components/signUp/SearchInsuranceCompanies'
import {
  isFailure,
  isLoading,
  isSuccess,
  LoadingContext,
} from '../../utils/types'
import OverlayItem from '../../components/common/OverlayItem'
import { useLoadingChange } from '../../hooks/useLoadingChange'
import { insuranceActions } from '../../store/insurance/actions'
import InsuranceInfoForm, {
  InsuranceInfoFormHandlers,
  InsuranceInfoFormValues,
} from '../../components/forms/InsuranceInfoForm'
import { getInsuranceVerifying } from '../../store/insurance/selectors'
import {
  makeGetAttachmentById,
  makeGetAttachmentFetchingById,
} from '../../store/attachments/selectors'
import {
  getUserInsuranceIsEligible,
  getUserProfile,
} from '../../store/auth/selectors'
import { RoutePath } from '../../routes'
import FooterControls from '../../components/FooterControls'
import ThemedButton from '../../components/common/ThemedButton'
import gtag from '../../utils/gtag'
import { attachmentsActions } from '../../store/attachments/actions'
import { getIsEmployerProgram } from '../../store/testOrdering/selectors'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(5, 0, 4),
      height: '100%',
      position: 'relative',
    },
    withoutPaddingBottom: {
      padding: theme.spacing(9, 0, 0),
    },
    text: {
      fontSize: '1.25rem',
      fontFamily: 'NeurialGrotesk',
      color: theme.palette.primary.dark,
      fontWeight: 700,
      lineHeight: '130%',
      marginBottom: theme.spacing(2),
    },
    content: {
      flex: 1,
      overflowY: 'auto',
      padding: theme.spacing(0, 4),
      marginBottom: theme.spacing(3),
    },
    searchListContainer: {
      position: 'absolute',
      top: 0,
      zIndex: 2,
      width: '100%',
      display: 'none',
      height: '100%',
    },
    showed: {
      display: 'initial',
    },
    hint: {
      fontFamily: 'NeurialGrotesk',
      fontSize: '0.75rem',
      lineHeight: '20px',
      color: '#7983AC',
      marginTop: theme.spacing(1),
    },
  }),
  { name: 'InsuranceInfo' }
)

const InsuranceInfo: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()

  const dispatch = useDispatch()

  const [submissionFailed, setSubmissionFailed] = useState(false)

  const profile = useSelector(getUserProfile)

  const insuranceVerifying = useSelector(getInsuranceVerifying)
  const insuranceIsVerifying = isLoading(insuranceVerifying.state)

  const isEmployerProgram = useSelector(getIsEmployerProgram)

  const personalInsuranceEligibility = useSelector(getUserInsuranceIsEligible)

  const isInsuranceEligible =
    profile?.employeeInsuranceInfo?.insuranceUnverifiable ||
    personalInsuranceEligibility

  const [showCompaniesSearch, setShowCompaniesSearch] = useState(false)

  const formRef = useRef<InsuranceInfoFormHandlers>(null)

  const userProfile = useSelector(getUserProfile) || {}

  const attachmentIdFront =
    userProfile?.insuranceInfo?.attachmentFrontPhotoId || null
  const attachmentIdBack =
    userProfile?.insuranceInfo?.attachmentBackPhotoId || null

  const getInsuranceAttachmentFront = useMemo(
    () => makeGetAttachmentById(attachmentIdFront),
    [attachmentIdFront]
  )
  const getInsuranceAttachmentBack = useMemo(
    () => makeGetAttachmentById(attachmentIdBack),
    [attachmentIdBack]
  )

  const attachmentFront = useSelector(getInsuranceAttachmentFront)
  const attachmentBack = useSelector(getInsuranceAttachmentBack)

  const getFetchingInsuranceFrontCard = useMemo(
    () => makeGetAttachmentFetchingById(attachmentIdFront),
    [attachmentIdFront]
  )
  const getFetchingInsuranceBackCard = useMemo(
    () => makeGetAttachmentFetchingById(attachmentIdBack),
    [attachmentIdBack]
  )

  const fetchingInsuranceFrontCard = useSelector(getFetchingInsuranceFrontCard)
  const fetchingInsuranceBackCard = useSelector(getFetchingInsuranceBackCard)

  const isFetchingInsuranceCards = useMemo(
    () =>
      isLoading(fetchingInsuranceFrontCard.state) ||
      isLoading(fetchingInsuranceBackCard.state),
    [fetchingInsuranceFrontCard, fetchingInsuranceBackCard]
  )

  useEffect(() => {
    if (
      attachmentIdFront &&
      attachmentIdBack &&
      !attachmentFront &&
      !attachmentBack
    ) {
      dispatch(
        attachmentsActions.getAttachment.request({
          attachmentId: attachmentIdFront,
        })
      )
      dispatch(
        attachmentsActions.getAttachment.request({
          attachmentId: attachmentIdBack,
        })
      )
    }
  }, [])

  const initialValues = {
    insuranceName: userProfile?.insuranceInfo?.insuranceName || '',
    insuranceId: userProfile?.insuranceInfo?.insuranceId || '',
    firstName: userProfile?.firstName,
    lastName: userProfile?.lastName,
    dob: moment(userProfile.dateOfBirth || userProfile.dob),
    front: attachmentFront,
    back: attachmentBack,
  }

  const handleGoNext = useCallback(() => {
    gtag(
      'event',
      submissionFailed
        ? 'click_retry_insurance_verification'
        : 'click_verify_insurance'
    )

    formRef?.current?.submit()
  }, [formRef, submissionFailed])

  const handleAnotherOption = useCallback(() => {
    gtag('event', 'click_try_another_option')

    history.push(RoutePath.insuranceAutoVerificationFailed)
  }, [history])

  const handleGoBack = useCallback(() => {
    history.goBack()
  }, [history])

  const handleFormSubmit = useCallback(
    ({ front, back, ...values }: InsuranceInfoFormValues) => {
      dispatch(
        insuranceActions.verifyInsurance.request({
          insuranceValues: values,
          images: { front, back },
          isEmployerProgram,
        })
      )
    },
    [dispatch, isEmployerProgram]
  )

  const handleShowSearchList = useCallback(() => {
    setShowCompaniesSearch(true)
  }, [setShowCompaniesSearch])

  const handleCloseSearch = useCallback(() => {
    setShowCompaniesSearch(false)
  }, [setShowCompaniesSearch])

  const handleCompanySelect = useCallback(
    (insuranceCompany) => {
      formRef?.current?.updateCompany(insuranceCompany)
      setShowCompaniesSearch(false)
    },
    [setShowCompaniesSearch, formRef]
  )

  const handleInsuranceVerifying = useCallback(
    (newLoading: LoadingContext) => {
      if (isSuccess(newLoading.state)) {
        setSubmissionFailed(!isInsuranceEligible)

        if (!isInsuranceEligible) {
          return
        }

        history.push(RoutePath.choosePaymentMethod)
      }

      if (isFailure(newLoading.state)) {
        gtag('event', 'webView_auto_insurance_failed', {
          message: newLoading.message,
        })
        alert(newLoading.message)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [personalInsuranceEligibility, isInsuranceEligible]
  )

  useLoadingChange(handleInsuranceVerifying, insuranceVerifying)

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={clsx(classes.root, {
        [classes.withoutPaddingBottom]: showCompaniesSearch,
      })}
    >
      {(insuranceIsVerifying || isFetchingInsuranceCards) && (
        <OverlayItem>
          <CircularProgress />
        </OverlayItem>
      )}
      <Grid
        className={clsx(classes.searchListContainer, {
          [classes.showed]: showCompaniesSearch,
        })}
      >
        <SearchInsuranceCompanies
          onCompanySelect={handleCompanySelect}
          onClose={handleCloseSearch}
        />
      </Grid>

      <Grid className={classes.content}>
        <Typography className={classes.text}>Insurance Information</Typography>

        <Typography className={classes.hint}>
          Please ensure the information below exactly matches your insurance
          card. Coverage may differ depending on your insurance.
        </Typography>

        {submissionFailed && (
          <Alert severity="error">
            <AlertTitle>Status: Insurance inactive</AlertTitle>
            (Automatic verification failed)
          </Alert>
        )}
        <InsuranceInfoForm
          ref={formRef}
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          onSearchListShow={handleShowSearchList}
        />
      </Grid>
      <Grid>
        <FooterControls paddingX={4}>
          <ThemedButton onClick={handleGoNext}>
            {submissionFailed ? 'Retry' : 'Verify'}
          </ThemedButton>

          {submissionFailed && (
            <ThemedButton onClick={handleAnotherOption}>
              Try another option
            </ThemedButton>
          )}

          <ThemedButton variant="text" onClick={handleGoBack}>
            Back
          </ThemedButton>
        </FooterControls>
      </Grid>
    </Grid>
  )
}

export default InsuranceInfo
