import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../index'
import { head } from 'lodash'

const getLabOrderState = (state: RootState) => state.testOrdering

export const getLabOrderDataSelector = createSelector(
  getLabOrderState,
  (state) => state.orderData
)

export const getLabOrderBiomarkers = createSelector(
  getLabOrderState,
  (state) => state.labDetails?.results.map((result) => result.fullName) || []
)

export const getFetchingLabOrderData = createSelector(
  getLabOrderState,
  (state) => state.fetchingOrderData
)

export const getLabOrderDraft = createSelector(
  getLabOrderState,
  (state) => state.labOrderDraft
)

export const getCreatingLabOrderDraft = createSelector(
  getLabOrderState,
  (state) => state.creatingLabOrderDraft
)

export const getLabOrderDataForDraftCreation = createSelector(
  getLabOrderState,
  (state) => {
    const selectedOption = state.orderData
      ? head(state.orderData.options)
      : null

    return state.orderData && selectedOption
      ? {
          productId: state.orderData.id,
          option: {
            optionId: selectedOption.id,
            optionValueId: head(selectedOption.optionValues)!.id,
          },
        }
      : null
  }
)

export const getUpdatingLabOrderDraftAddress = createSelector(
  getLabOrderState,
  (state) => state.updatingLabOrderDraftAddress
)

export const getUpdatingLabOrderDraftBillingType = createSelector(
  getLabOrderState,
  (state) => state.updatingLabOrderDraftBillingType
)

export const getConfirmingLabOrderDraft = createSelector(
  getLabOrderState,
  (state) => state.confirmingLabOrderDraft
)

export const getPayingForLabOrder = createSelector(
  getLabOrderState,
  (state) => state.payingForLabOrder
)

export const getConsultationExistence = createSelector(
  getLabOrderState,
  (state) => state.consultationExistence
)

export const getLabOrderReceipt = createSelector(
  getLabOrderState,
  (state) => state.labOrderReceipt
)

export const getFetchingLabOrderReceipt = createSelector(
  getLabOrderState,
  (state) => state.fetchingLabOrderReceipt
)

export const getConfirmedOrderId = createSelector(
  getLabOrderState,
  (state) => state.confirmedOrderId
)

export const getIsEmployerProgram = createSelector(
  getLabOrderState,
  (state) => state.isEmployerProgram
)

export const getJoiningCorporateProgram = createSelector(
  getLabOrderState,
  (state) => state.joiningCorporateProgram
)

export const getJoinCorporateProgramInfo = createSelector(
  getLabOrderState,
  (state) => state.joinCorporateProgramInfo
)
