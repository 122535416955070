import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Grid, Link, Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'

import authActions from 'src/store/auth/actions'
import FooterButtons from '../../../components/FooterButtons'
import {
  getIsBlacklistedAccount,
  getIsDeletedAccount,
  getOutdatedTosList,
  getSignInLoading,
  getUserAnalyticId,
} from '../../../store/auth/selectors'
import {
  isFailure,
  isLoading,
  isSuccess,
  LoadingContext,
} from '../../../utils/types'
import { RoutePath } from '../../../routes'
import { useLoadingChange } from '../../../hooks/useLoadingChange'
import gtag from '../../../utils/gtag'
import SignInForm, {
  SignInFormValues,
} from '../../../components/forms/SignInForm'
import ConfirmationDialog, {
  ActionsDirection,
} from '../../../components/dialogs/ConfirmationDialog'
import { useIsLabOrderingFlow } from '../../../hooks/labOrdering/useIsLabOrderingFlow'
import { useLabOrderDraftCreate } from '../../../hooks/labOrdering/useLabOrderDraftCreate'
import useLoadingSuccess from '../../../hooks/useLoadingSuccess'
import { DELETED_ACCOUNT_MESSAGE } from '../../../utils/consts/auth'
import parsePhoneNumber from 'libphonenumber-js'
import { SUPPORT_PHONE_NUMBER } from '../../../utils/general'
import BlacklistedAccountMessage from '../../../components/signUp/BlacklistedAccountMessage'
import { trackLoginEvent } from '../../../utils/analytics'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(9, 4, 4),
      position: 'relative',
    },
    text: {
      fontFamily: 'NeurialGrotesk',
    },
    title: {
      fontWeight: 700,
      fontSize: '1.375rem',
      letterSpacing: '-0.5px',
      color: theme.palette.primary.dark,
      lineHeight: 1.5,
    },
    errorText: {
      color: theme.palette.error.main,
      fontSize: '0.875rem',
    },
    link: {
      fontFamily: 'NeurialGrotesk',
      fontSize: '0.875rem',
      lineHeight: 1.25,
      color: theme.palette.primary.dark,
      cursor: 'pointer',
    },
    linkContainer: {
      padding: theme.spacing(3, 0),
    },
    disabled: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  }),
  { name: 'SignIn' }
)

const SignIn: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const isLabOrderingFlow = useIsLabOrderingFlow()

  const [showDeletedAccountDialog, setShowDeletedAccountDialog] = useState(
    false
  )
  const [temporaryEmail, setTemporaryEmail] = useState('')

  const signInLoading = useSelector(getSignInLoading)
  const signInIsLoading = isLoading(signInLoading.state)

  const isDeletedAccount = useSelector(getIsDeletedAccount)
  const isBlacklistedAccount = useSelector(getIsBlacklistedAccount)

  const userAnalyticId = useSelector(getUserAnalyticId)

  const [generalValidationError, setGeneralValidationError] = useState('')

  const outdatedTosList = useSelector(getOutdatedTosList)

  const {
    creating: creatingLabOrderDraft,
    createLabOrderDraft,
  } = useLabOrderDraftCreate()
  const isCreatingLabOrderDraft = isLoading(creatingLabOrderDraft.state)

  const handleSuccessfulProceed = useCallback(() => {
    if (isLabOrderingFlow) {
      createLabOrderDraft()
    } else {
      history.push(RoutePath.createMedicalAccount)
    }
  }, [history, isLabOrderingFlow, createLabOrderDraft])

  const handleSignInChange = useCallback(
    (newLoading: LoadingContext) => {
      if (isSuccess(newLoading.state)) {
        if (userAnalyticId) {
          window.analytics?.identify?.(userAnalyticId)
        }

        if (outdatedTosList.length) {
          history.push(RoutePath.outdatedTos)
        } else {
          handleSuccessfulProceed()
          trackLoginEvent()
        }
      }
      if (isFailure(newLoading.state)) {
        gtag('event', 'webView_login_failed', {
          message: newLoading.message,
        })

        if (isDeletedAccount || isBlacklistedAccount) {
          setShowDeletedAccountDialog(true)
        } else {
          setGeneralValidationError(newLoading.message as string)
        }

        dispatch(authActions.clearAccountDeletedState())
      }
    },
    [
      history,
      setGeneralValidationError,
      isDeletedAccount,
      isBlacklistedAccount,
      userAnalyticId,
      JSON.stringify(outdatedTosList),
      handleSuccessfulProceed,
    ]
  )

  useLoadingChange(handleSignInChange, signInLoading)

  useLoadingSuccess(creatingLabOrderDraft, () => {
    history.replace(RoutePath.chooseLabOrderPaymentMethod)
  })

  const formRef = useRef<any>(null)

  const handleGoNext = useCallback(() => {
    formRef?.current?.submit()
    setGeneralValidationError('')
  }, [formRef, setGeneralValidationError])

  const handleGoBack = useCallback(() => {
    history.goBack()

    dispatch(authActions.clearData())
  }, [history])

  const handleFormSubmit = useCallback(
    (data: SignInFormValues) => {
      dispatch(authActions.signIn.request({ ...data }))
      setTemporaryEmail(data.email)
    },
    [dispatch, setTemporaryEmail]
  )

  const handleOpenForgot = useCallback(() => {
    history.push(RoutePath.forgotPassword)
  }, [history])

  const formIsLoading = useMemo(
    () => signInIsLoading || isCreatingLabOrderDraft,
    [signInIsLoading, isCreatingLabOrderDraft]
  )

  const handleReactivateAccount = () => {
    history.push(RoutePath.reactivateAccount)
  }

  const handleCallSupport = () => {
    window.open(
      parsePhoneNumber(SUPPORT_PHONE_NUMBER)?.getURI() || '',
      '_blank'
    )
  }

  useEffect(() => {
    gtag('event', 'apptstart_login')
  }, [])

  return (
    <Grid className={classes.root} container direction="column">
      <Typography className={clsx(classes.text, classes.title)}>
        Sign In
      </Typography>

      <Grid container item xs direction="column" justifyContent="flex-end">
        <SignInForm
          ref={formRef}
          onSubmit={handleFormSubmit}
          disabled={formIsLoading}
        />
        <Grid
          container
          item
          justifyContent="flex-end"
          className={classes.linkContainer}
        >
          <Link
            onClick={handleOpenForgot}
            className={clsx(classes.link, {
              [classes.disabled]: formIsLoading,
            })}
          >
            Forgot password?
          </Link>
        </Grid>

        <Typography
          align="center"
          className={clsx(classes.text, classes.errorText)}
        >
          {generalValidationError}
        </Typography>
        <FooterButtons
          nextButtonLabel="Log In"
          backButtonLabel="Back"
          onNextButtonClick={handleGoNext}
          onBackButtonClick={handleGoBack}
          loadingNext={formIsLoading}
          disableBack={formIsLoading}
        />
      </Grid>
      <ConfirmationDialog
        actionsDirection={
          isBlacklistedAccount ? ActionsDirection.column : ActionsDirection.row
        }
        open={showDeletedAccountDialog}
        confirmButtonLabel={
          isBlacklistedAccount
            ? parsePhoneNumber(SUPPORT_PHONE_NUMBER)?.formatNational()
            : undefined
        }
        content={
          isBlacklistedAccount ? (
            <BlacklistedAccountMessage email={temporaryEmail} />
          ) : (
            DELETED_ACCOUNT_MESSAGE
          )
        }
        onClose={() => setShowDeletedAccountDialog(false)}
        onConfirm={
          isBlacklistedAccount ? handleCallSupport : handleReactivateAccount
        }
      />
    </Grid>
  )
}

export default SignIn
