import React, { useCallback, useEffect } from 'react'
import { Button, Box, Stack, Typography, CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'

import { useLabOrderData } from '../../hooks/labOrdering/useLabOrderData'
import OverlayItem from '../../components/common/OverlayItem'
import { isLoading } from '../../utils/types'
import LabOrderCard from '../../components/labOrdering/LabOrderCard'
import { RoutePath } from '../../routes'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(9, 4, 4),
      height: '100%',
    },
    text: {
      color: theme.palette.primary.dark,
      lineHeight: 1.5,
    },
    title: {
      fontFamily: 'NeurialGrotesk',
      fontWeight: 700,
      fontSize: '1.375rem',
      letterSpacing: '-0.5px',
      marginBottom: theme.spacing(3),
    },
    buttonRoot: {
      fontFamily: 'NeurialGrotesk',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.3,
      textAlign: 'center',
      color: theme.palette.primary.main,
      borderRadius: '20px',
      height: 50,
      width: '100%',
      border: `2px solid ${theme.palette.primary.light}`,
      '&:hover': {
        border: `2px solid ${theme.palette.primary.main}`,
      },
    },
    infoWrapper: {
      position: 'relative',
    },
    infoContainer: {
      padding: theme.spacing(3),
      border: `2px solid ${theme.palette.primary.light}`,
      borderRadius: 20,
    },
  }),
  { name: 'ConfirmLabOrder' }
)

export type ConfirmLabOrderProps = {
  labOrderId?: string
}

const ConfirmLabOrder: React.FC<ConfirmLabOrderProps> = ({ labOrderId }) => {
  const classes = useStyles()
  const history = useHistory()

  const { fetching, order, getLabOrderData } = useLabOrderData()

  useEffect(() => {
    getLabOrderData(Number(labOrderId))
  }, [getLabOrderData])

  const handleCreateNewAccount = useCallback(() => {
    history.push(RoutePath.checkEmail)
  }, [history])

  const handleSignIn = useCallback(() => {
    history.push(RoutePath.signIn)
  }, [history])

  return (
    <Stack justifyContent="space-between" className={classes.root}>
      <Stack spacing={4}>
        <Box>
          <Typography className={clsx(classes.text, classes.title)}>
            Confirm Lab Test Order
          </Typography>
          <Stack spacing={3}>
            <Typography align="left" className={classes.text}>
              To confirm your lab test order we need to collect information such
              as Name, Address and potentially Insurance Data.
            </Typography>
            <Typography align="left" className={classes.text}>
              If you already have an account with us, please sign in. If not,
              please press &quot;Create new account.&quot;
            </Typography>
          </Stack>
        </Box>

        <Box className={classes.infoWrapper}>
          {isLoading(fetching.state) && (
            <OverlayItem>
              <CircularProgress />
            </OverlayItem>
          )}

          <Stack spacing={3} className={classes.infoContainer}>
            <Stack spacing={1.5}>
              <Button
                variant="outlined"
                color="primary"
                className={classes.buttonRoot}
                onClick={handleCreateNewAccount}
              >
                Create new account
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className={classes.buttonRoot}
                onClick={handleSignIn}
              >
                I have an account, Sign In
              </Button>
            </Stack>

            {order && <LabOrderCard order={order} />}
          </Stack>
        </Box>
      </Stack>
    </Stack>
  )
}

export default ConfirmLabOrder
