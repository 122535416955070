import React, { PropsWithChildren, useEffect, useState } from 'react'
import { Grid, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Route, Switch } from 'react-router-dom'
import clsx from 'clsx'

import { RoutePath } from './routes'
import ActionUrgentCareHomePage from './pages/createAppointment/ActionUrgentCareHomePage'
import AppointmentTime from './pages/createAppointment/AppointmentTime'
import ReasonsPage from './pages/createAppointment/ReasonsPage'
import SelectedClinicHomePage from './pages/createAppointment/SelectedClinicHomePage'
import AtHomeCare from './pages/createAppointment/AtHomeCare'
import ConfirmAppointment from './pages/auth/signUp/ConfirmAppointment'
import CreateAccount, {
  CreateAccountProps,
} from './pages/auth/signUp/CreateAccount'
import AppointmentConfirmed from './pages/auth/signUp/AppointmentConfirmed'
import SignIn from './pages/auth/signIn/SignIn'
import ForgotPassword from './pages/auth/signIn/ForgotPassword'
import AtHomeCareFailure from './pages/createAppointment/AtHomeCareFailure'
import ResetPassword from './pages/auth/resetPassword/ResetPassword'
import ResetPasswordConfirmation from './pages/auth/resetPassword/ResetPasswordConfirmation'
import VerificationOptions from './pages/signUp/VerificationOptions'
import VerificationPage from './pages/signUp/VerificationPage'
import NoAppointmentsAvailable from './pages/createAppointment/NoAppointmentsAvailable'
import VerifyEmail from './pages/auth/signUp/VerifyEmail'
import ReactivateAccount from './pages/auth/signUp/ReactivateAccount'
import CreateNewPassword from './pages/auth/signUp/CreateNewPassword'
import CreatePasswordForNewAcc from './pages/auth/signUp/CreatePasswordForNewAcc'
import TosesAndSignature from './pages/createAppointment/TosesAndSignature'
import CheckEmail from './pages/auth/signUp/CheckEmail'
import CopayPayment from './pages/createAppointment/CopayPayment'
import ChoosePaymentMethod from './pages/createAppointment/ChoosePaymentMethod'
import ChoosePaymentCard from './pages/createAppointment/ChoosePaymentCard'
import AddPaymentMethod from './pages/createAppointment/AddPaymentMethod'
import InsuranceInfo from './pages/signUp/InsuranceInfo'
import InsuranceAutoVerificationFailed from './pages/signUp/InsuranceAutoVerificationFailed'
import CreateMedicalAccount from './pages/createAppointment/CreateMedicalAccount'
import InsuranceManualVerification from './pages/signUp/InsuranceManualVerification'
import InsuranceManualVerificationSuccess from './pages/signUp/InsuranceManualVerificationSuccess'
import InsuranceManualVerificationFailed from './pages/signUp/InsuranceManualVerificationFailed'
import CreateApptLoading from './pages/createAppointment/CreateApptLoading'
import PaymentSuccessful from './pages/createAppointment/PaymentSuccessful'
import CheckPendingAppt from './pages/createAppointment/CheckPendingAppt'
import SymptomsCheckerResults from './pages/symptomsChecker/SymptomsCheckerResults'
import { WidgetTypes } from './utils/general'
import NavigationAnalytics from './containers/NavigationAnalytics'
import AddressInformation from './pages/auth/signUp/AddressInformation'
import CreateMedicalAccountFailure from './pages/createAppointment/CreateMedicalAccountFailure'
import IdCard from './pages/createAppointment/IdCard'
import InClinicHomePage from './pages/createAppointment/inClinicGeneral/InClinicHomePage'
import ConfirmedAppointmentDetails from './containers/ConfirmedAppointmentDetails'
import DetailedInsuranceInfo from './pages/DetailedInsuranceInfo'
import OutdatedTosPage from './pages/auth/signIn/OutdatedTosPage'
import ConfirmLabOrder, {
  ConfirmLabOrderProps,
} from './pages/labOrdering/ConfirmLabOrder'
import ChooseLabOrderPaymentMethod from './pages/labOrdering/ChooseLabOrderPaymentMethod'
import ChooseConsultationExistence from './pages/labOrdering/ChooseConsultationExistence'
import LabOrderPaymentSuccessful from './pages/labOrdering/LabOrderPaymentSuccessful'
import LabOrderInformation, {
  LabOrderInformationProps,
} from './pages/labOrdering/LabOrderInforamtion'
import { SymptomsCheckerParams } from './utils/labOrdering'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      display: 'flex',
      width: 403,
      height: 840,
      borderRadius: 40,
      background: theme.palette.background.default,
      border: '1px solid rgba(0, 0, 0, 0.3)',
      boxSizing: 'border-box',
    },

    rootAsPartOfThePage: {
      background: 'transparent',
      border: 'none',
      height: 'auto',
    },
  }),
  { name: 'Body' }
)

interface BodyProps {
  clinicId: string
  examRoom: string
  widgetType: string
  eventName: string
  isSymptomatic: boolean
  symptomsCheckerParams?: SymptomsCheckerParams
  labOrderId?: string
}

const KYLA_WEB_MOBILE_BREAKPOINT = 640

const Body: React.FC<BodyProps> = ({
  clinicId,
  examRoom,
  widgetType,
  eventName,
  isSymptomatic,
  symptomsCheckerParams,
  labOrderId,
}) => {
  const classes = useStyles()

  const [parentWidth, setParentWidth] = useState(
    window.parent.innerWidth ||
      window.parent.document.documentElement.clientWidth
  )

  useEffect(() => {
    const handleResize = () => {
      setParentWidth(
        window.parent.innerWidth ||
          window.parent.document.documentElement.clientWidth
      )
    }

    window.parent.addEventListener('resize', handleResize)

    return () => {
      window.parent.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Grid
      className={clsx(classes.root, {
        [classes.rootAsPartOfThePage]:
          parentWidth < KYLA_WEB_MOBILE_BREAKPOINT &&
          widgetType === WidgetTypes.labOrderInfo,
      })}
    >
      <Switch>
        <Route
          exact
          path={RoutePath.actionUrgentCareHome}
          component={(props: PropsWithChildren<any>) => (
            <ActionUrgentCareHomePage {...props} defaultClinicId={clinicId} />
          )}
        />
        <Route
          exact
          path={RoutePath.inClinicHome}
          component={(props: PropsWithChildren<any>) => (
            <InClinicHomePage {...props} />
          )}
        />
        <Route
          exact
          path={RoutePath.selectedClinicHome}
          component={(props: PropsWithChildren<any>) => (
            <SelectedClinicHomePage
              {...props}
              eventName={eventName}
              initialPage={RoutePath.selectedClinicHome}
              selectedClinicId={Number(clinicId)}
              examRoom={Number(examRoom)}
              widgetType={widgetType}
              isSymptomatic={isSymptomatic}
            />
          )}
        />
        <Route
          exact
          path={RoutePath.virtualVisit}
          component={(props: PropsWithChildren<any>) => (
            <SelectedClinicHomePage
              {...props}
              initialPage={RoutePath.virtualVisit}
              selectedClinicId={Number(clinicId)}
              widgetType={widgetType}
            />
          )}
        />
        <Route
          exact
          path={RoutePath.atHomeCare}
          component={(props: PropsWithChildren<any>) => (
            <AtHomeCare
              {...props}
              selectedClinicId={Number(clinicId)}
              widgetType={widgetType}
            />
          )}
        />
        <Route
          exact
          path={RoutePath.atHomeCareFailure}
          component={(props: PropsWithChildren<any>) => (
            <AtHomeCareFailure
              {...props}
              selectedClinicId={Number(clinicId)}
              widgetType={widgetType}
            />
          )}
        />
        <Route
          exact
          path={RoutePath.appointmentTime}
          component={(props: PropsWithChildren<any>) => (
            <AppointmentTime {...props} />
          )}
        />
        <Route
          exact
          path={RoutePath.appointmentReasons}
          component={(props: PropsWithChildren<any>) => (
            <ReasonsPage {...props} widgetType={widgetType} />
          )}
        />
        <Route
          exact
          path={RoutePath.confirmAppointment}
          component={ConfirmAppointment}
        />
        <Route exact path={RoutePath.checkEmail} component={CheckEmail} />
        <Route
          exact
          path={RoutePath.createAccount}
          component={(props: PropsWithChildren<CreateAccountProps>) => (
            <CreateAccount
              {...props}
              defaultValues={symptomsCheckerParams?.patientInfo}
            />
          )}
        />

        <Route exact path={RoutePath.verifyEmail} component={VerifyEmail} />
        <Route
          exact
          path={RoutePath.reactivateAccount}
          component={ReactivateAccount}
        />
        <Route
          exact
          path={RoutePath.createNewPassword}
          component={CreateNewPassword}
        />
        <Route
          exact
          path={RoutePath.createPassword}
          component={(props: any) => (
            <CreatePasswordForNewAcc
              {...props}
              patientInfo={symptomsCheckerParams?.patientInfo}
            />
          )}
        />
        <Route
          exact
          path={RoutePath.addSignature}
          component={TosesAndSignature}
        />
        <Route exact path={RoutePath.idCard} component={IdCard} />
        <Route
          exact
          path={RoutePath.verificationOptions}
          component={VerificationOptions}
        />
        <Route
          exact
          path={RoutePath.verificationPage}
          component={VerificationPage}
        />
        <Route
          exact
          path={RoutePath.noAppointmentAvailable}
          component={NoAppointmentsAvailable}
        />
        <Route
          exact
          path={RoutePath.appointmentConfirmed}
          component={AppointmentConfirmed}
        />
        <Route
          exact
          path={RoutePath.choosePaymentMethod}
          component={ChoosePaymentMethod}
        />

        <Route exact path={RoutePath.copayPayment} component={CopayPayment} />

        <Route
          exact
          path={RoutePath.choosePaymentCard}
          component={ChoosePaymentCard}
        />

        <Route
          exact
          path={RoutePath.addPaymentMethod}
          component={AddPaymentMethod}
        />

        <Route exact path={RoutePath.signIn} component={SignIn} />
        <Route
          exact
          path={RoutePath.forgotPassword}
          component={ForgotPassword}
        />
        <Route exact path={RoutePath.resetPassword} component={ResetPassword} />
        <Route
          exact
          path={RoutePath.resetPasswordConfirm}
          component={ResetPasswordConfirmation}
        />
        <Route exact path={RoutePath.insuranceInfo} component={InsuranceInfo} />
        <Route
          exact
          path={RoutePath.insuranceAutoVerificationFailed}
          component={InsuranceAutoVerificationFailed}
        />
        <Route
          exact
          path={RoutePath.createMedicalAccount}
          component={CreateMedicalAccount}
        />

        <Route
          exact
          path={RoutePath.checkCronoAccountFailure}
          component={CreateMedicalAccountFailure}
        />

        <Route
          exact
          path={RoutePath.insuranceManualVerification}
          component={InsuranceManualVerification}
        />

        <Route
          exact
          path={RoutePath.insuranceManualVerificationSuccess}
          component={InsuranceManualVerificationSuccess}
        />

        <Route
          exact
          path={RoutePath.insuranceManualVerificationFailure}
          component={InsuranceManualVerificationFailed}
        />

        <Route
          exact
          path={RoutePath.createAppointment}
          component={CreateApptLoading}
        />

        <Route
          exact
          path={RoutePath.paymentSuccessful}
          component={PaymentSuccessful}
        />
        <Route
          exact
          path={RoutePath.checkPendingAppt}
          component={CheckPendingAppt}
        />

        <Route
          exact
          path={RoutePath.addressInfo}
          component={AddressInformation}
        />

        <Route
          exact
          path={RoutePath.symptomsCheckerResults}
          component={(props: PropsWithChildren<any>) => (
            <SymptomsCheckerResults
              {...props}
              symptomsCheckerParams={symptomsCheckerParams}
            />
          )}
        />

        <Route
          exact
          path={RoutePath.confirmedAppointmentDetails}
          component={ConfirmedAppointmentDetails}
        />

        <Route
          exact
          path={RoutePath.insuranceDetailedInfo}
          component={DetailedInsuranceInfo}
        />

        <Route exact path={RoutePath.outdatedTos} component={OutdatedTosPage} />

        <Route
          exact
          path={RoutePath.confirmLabOrderRoute}
          component={(props: PropsWithChildren<ConfirmLabOrderProps>) => (
            <ConfirmLabOrder {...props} labOrderId={labOrderId} />
          )}
        />

        <Route
          exact
          path={RoutePath.chooseLabOrderPaymentMethod}
          component={ChooseLabOrderPaymentMethod}
        />

        <Route
          exact
          path={RoutePath.chooseConsultationExistence}
          component={ChooseConsultationExistence}
        />

        <Route
          exact
          path={RoutePath.labOrderPaymentSuccessful}
          component={LabOrderPaymentSuccessful}
        />

        <Route
          exact
          path={RoutePath.labOrderInfo}
          component={(props: PropsWithChildren<LabOrderInformationProps>) => (
            <LabOrderInformation
              {...props}
              labOrderId={labOrderId}
              patientInfo={symptomsCheckerParams?.patientInfo}
            />
          )}
        />
      </Switch>

      <NavigationAnalytics widgetType={widgetType} />
    </Grid>
  )
}

export default Body
