import React, { useCallback, useEffect, useMemo } from 'react'
import { Button, Grid, Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { useDispatch, useSelector } from 'react-redux'

import appointmentActions from 'src/store/appointment/actions'
import {
  getAppointmentClinic,
  getAppointmentTime,
} from '../../../store/appointment/selectors'
import { RoutePath } from '../../../routes'
import AppointmentButton from '../../../components/common/AppointmentButton'
import gtag from '../../../utils/gtag'
import { TelemedicineWidgetTypes } from '../../../utils/appointment/general'
import { analyticsActions } from '../../../store/analytics/actions'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(9, 4, 4),
      height: '100%',
    },
    text: {
      color: theme.palette.primary.dark,
      lineHeight: 1.5,
    },
    title: {
      fontFamily: 'NeurialGrotesk',
      fontWeight: 700,
      fontSize: '1.375rem',
      letterSpacing: '-0.5px',
    },
    buttonRoot: {
      fontFamily: 'NeurialGrotesk',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.3,
      textAlign: 'center',
      color: theme.palette.primary.main,
      borderRadius: '20px',
      height: 50,
      width: '100%',
      border: `2px solid ${theme.palette.primary.light}`,
      '&:hover': {
        border: `2px solid ${theme.palette.primary.main}`,
      },
      marginTop: theme.spacing(1.5),
    },
    infoWrapper: {
      marginTop: theme.spacing(3),
    },
    marginBottom: {
      marginBottom: theme.spacing(3),
    },
  }),
  { name: 'ConfirmAppointment' }
)

const ConfirmAppointment: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  // eslint-disable-next-line no-restricted-globals
  const query = queryString.parse(location.search)

  const { sourceWidgetType, analyticsQueries } = query

  const selectedClinicInfo = useSelector(getAppointmentClinic)
  const appointmentTime = useSelector(getAppointmentTime)

  const isTelemedicineClinic = useMemo(
    () => TelemedicineWidgetTypes.includes(sourceWidgetType as string),
    [sourceWidgetType]
  )

  useEffect(() => {
    if (analyticsQueries) {
      dispatch(
        analyticsActions.storeAnalyticsQueries({
          queries: JSON.parse(window.atob(analyticsQueries as string)),
        })
      )
    }
  }, [analyticsQueries])

  useEffect(() => {
    dispatch(
      appointmentActions.preselectSourceWidgetType({
        sourceWidgetType: sourceWidgetType as string,
      })
    )
  }, [])

  const handleCreateNewAccount = useCallback(() => {
    history.push(RoutePath.checkEmail)
  }, [history])

  const handleSignIn = useCallback(() => {
    history.push(RoutePath.signIn)
  }, [history])

  useEffect(() => {
    gtag('event', 'main_apptstart')
  }, [])

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={classes.root}
    >
      <Grid>
        <Typography className={clsx(classes.text, classes.title)}>
          Confirm Appointment
        </Typography>
        <Grid className={classes.infoWrapper}>
          <Typography
            align="left"
            className={clsx(classes.text, classes.marginBottom)}
          >
            To confirm your appointment we need to collect information such as
            Name, Date of Birth and potentially Insurance Data.
          </Typography>
          <Typography align="left" className={classes.text}>
            If you already have an account with us, please sign in. If not,
            please press &quot;Create new account.&quot;
          </Typography>
        </Grid>

        <AppointmentButton
          appointmentTime={appointmentTime}
          selectedClinicInfo={selectedClinicInfo}
          isTelemedicineClinic={isTelemedicineClinic}
        >
          <Grid className={classes.marginBottom}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.buttonRoot}
              onClick={handleCreateNewAccount}
            >
              Create new account
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className={classes.buttonRoot}
              onClick={handleSignIn}
            >
              I have an account, Sign In
            </Button>
          </Grid>
        </AppointmentButton>
      </Grid>
    </Grid>
  )
}

export default ConfirmAppointment
