import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
  Box,
} from '@mui/material'
import clsx from 'clsx'
import { isNil, noop } from 'lodash'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import makeStyles from '@mui/styles/makeStyles'

import { getPatientOfficeAllyInfoSelector } from '../../store/insurance/selectors'
import { TelemedicineWidgetTypes } from '../../utils/appointment/general'
import { getSourceWidgetType } from '../../store/appointment/selectors'
import {
  getUserInsuranceIsEligible,
  getUserProfile,
} from '../../store/auth/selectors'
import { InsuranceStatus } from '../../store/insurance/models'
import { getIsEmployerProgram } from '../../store/testOrdering/selectors'

const useStyles = makeStyles((theme) => ({
  accordion: {
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    boxShadow: 'none',
    borderBottomLeftRadius: '0 !important',
    borderBottomRightRadius: '0 !important',
  },
  accordionSummary: {
    padding: 0,
    minHeight: '0 !important',
    '& .MuiAccordionSummary-content': {
      margin: '8px 0 !important',
    },
  },
  accordionDetails: {
    padding: theme.spacing(1),
    borderRadius: '8px',
    background: 'rgba(236, 244, 255, 1)',
    marginBottom: theme.spacing(0.5),
  },

  text: {
    fontFamily: 'NeurialGrotesk',
    color: theme.palette.primary.dark,
  },
  successText: {
    color: theme.palette.success.main,
  },
  errorText: {
    color: theme.palette.error.main,
  },
  warningText: {
    color: theme.palette.warning.main,
  },
  hintText: {
    opacity: 0.7,
  },

  spaceBetween: {
    justifyContent: 'space-between',
  },
  flexDirection: {
    columnDirection: true,
  },
  columnDirection: {
    flexDirection: 'column',
  },
  withoutPointerEvents: {
    pointerEvents: 'none',
  },
}))

type InsuranceInfoBlock = {
  title: string
  condition?: boolean
  getData?(): { value: string; classes: string[] }
  placeholder: string
  value: string
  valueTextAdditionalClass?: string
  placeholderAdditionalClass?: string
  spaceBetween?: boolean
  columnDirection?: boolean
  expandable?: boolean
  details?: React.ReactNode
}

type DetailedInsuranceInfoCardProps = {
  withExpansion?: boolean
}

const DetailedInsuranceInfoCard: React.FC<DetailedInsuranceInfoCardProps> = ({
  withExpansion = false,
}) => {
  const classes = useStyles()

  const [expanded, setExpanded] = React.useState<Record<string, boolean>>({})

  const profile = useSelector(getUserProfile)

  const sourceWidgetType = useSelector(getSourceWidgetType)

  const isEmployerProgram = useSelector(getIsEmployerProgram)

  const isVirtual = useMemo(
    () => TelemedicineWidgetTypes.includes(sourceWidgetType),
    [sourceWidgetType]
  )

  const patientOfficeAllyInfo = useSelector(getPatientOfficeAllyInfoSelector)

  const copayAmount = isNil(profile.employeeInsuranceInfo?.copay)
    ? isVirtual
      ? patientOfficeAllyInfo?.copayTelemedicine
      : patientOfficeAllyInfo?.copayUrgentCare
    : profile.employeeInsuranceInfo?.copay

  const coinsurance = isVirtual
    ? patientOfficeAllyInfo?.coinsuranceTelemedicine
    : patientOfficeAllyInfo?.coinsuranceUrgentCare

  const deductibleAmount = isNil(profile.employeeInsuranceInfo?.deductible)
    ? patientOfficeAllyInfo?.deductibleIndividual
    : profile.employeeInsuranceInfo?.deductible

  const personalInsuranceEligibility = useSelector(getUserInsuranceIsEligible)

  const hasValidInsurance =
    profile?.employeeInsuranceInfo?.insuranceUnverifiable ||
    personalInsuranceEligibility

  const insuranceName = isEmployerProgram
    ? profile?.employeeInsuranceInfo?.companyInsurance
    : profile?.insuranceName

  const getInsuranceStatusData = () => {
    if (profile?.employeeInsuranceInfo?.insuranceUnverifiable) {
      return { value: InsuranceStatus.Limited, classes: [classes.warningText] }
    }

    if (hasValidInsurance) {
      return { value: InsuranceStatus.Verified, classes: [classes.successText] }
    }

    return {
      value: InsuranceStatus.NotVerified,
      classes: [classes.errorText],
    }
  }

  const insuranceInfoBlocks: InsuranceInfoBlock[] = hasValidInsurance
    ? [
        {
          title: 'Status',
          placeholder: 'Not Verified',
          value: 'Verified',
          spaceBetween: true,
          expandable: true,
          getData: getInsuranceStatusData,
          details: (
            <Stack>
              <Typography
                variant="body2"
                className={clsx(classes.text, classes.hintText)}
              >
                Verified –
              </Typography>
              <Typography
                variant="body2"
                className={clsx(classes.text, classes.hintText)}
              >
                Not Verified –
              </Typography>
              <Typography
                variant="body2"
                className={clsx(classes.text, classes.hintText)}
              >
                Expired –
              </Typography>
              <Typography
                variant="body2"
                className={clsx(classes.text, classes.hintText)}
              >
                Limited –
              </Typography>
            </Stack>
          ),
        },
        {
          title: 'Copay',
          condition: !isNil(copayAmount),
          placeholder: 'N/A',
          value: `${copayAmount}$`,
          spaceBetween: true,
          expandable: true,
          details: (
            <Typography
              variant="body2"
              className={clsx(classes.text, classes.hintText)}
            >
              A fixed amount you pay for a covered health care service after
              you&apos;ve paid your deductible.
            </Typography>
          ),
        },
        {
          title: 'Deductible Remaining',
          condition: !isNil(deductibleAmount),
          placeholder: 'N/A',
          value: `${deductibleAmount}$`,
          spaceBetween: true,
          expandable: true,
          details: (
            <Typography
              variant="body2"
              className={clsx(classes.text, classes.hintText)}
            >
              The amount you pay for covered health care services before your
              insurance plan starts to pay. With a $2,000 deductible, for
              example, you pay the first $2,000 of covered services yourself.
              After you pay your deductible, you usually pay only a copayment.
            </Typography>
          ),
        },
        {
          title: 'Coinsurance',
          condition: !isNil(coinsurance),
          placeholder: 'N/A',
          value: `${coinsurance}%`,
          spaceBetween: true,
          expandable: true,
          details: (
            <Typography
              variant="body2"
              className={clsx(classes.text, classes.hintText)}
            >
              The percentage of costs of the total covered health care service
              you pay after you’ve paid your deductible.
            </Typography>
          ),
        },
        {
          title: 'Out-of-Pocket Remaining',
          condition: !isNil(
            patientOfficeAllyInfo?.outOfPocketRemainingIndividual
          ),
          placeholder: 'N/A',
          value: `${patientOfficeAllyInfo?.outOfPocketRemainingIndividual}$`,
          spaceBetween: true,
          expandable: true,
          details: (
            <Typography
              variant="body2"
              className={clsx(classes.text, classes.hintText)}
            >
              Amount of money that an individual is still required to pay for
              covered healthcare services and expenses after deductibles,
              copayments, and coinsurance have been applied.
            </Typography>
          ),
        },
        {
          title: 'Insurance Type',
          condition: Boolean(
            profile?.insuranceInfo?.insuranceTypeCode?.fullName
          ),
          placeholder: 'N/A',
          value: profile?.insuranceInfo?.insuranceTypeCode?.fullName as string,
          spaceBetween: true,
          expandable: true,
          details: (
            <Typography
              variant="body2"
              className={clsx(classes.text, classes.hintText)}
            >
              Preferred Provider Organization (PPO). A type of health plan that
              contracts with medical providers.
            </Typography>
          ),
        },
        {
          title: 'Network Coverage',
          condition: Boolean(patientOfficeAllyInfo?.inNetwork),
          placeholder: 'N/A',
          value: 'Yes',
          spaceBetween: true,
        },
        {
          title: 'Insurance ID',
          condition: Boolean(profile?.insuranceId),
          placeholder: 'N/A',
          value: profile?.insuranceId as string,
          spaceBetween: true,
        },
        {
          title: 'Insurance Name',
          condition: Boolean(insuranceName),
          placeholder: 'N/A',
          value: insuranceName as string,
          columnDirection: true,
        },
      ]
    : [
        {
          title: 'Status',
          condition: Boolean(hasValidInsurance),
          placeholder: 'Not Verified',
          value: 'Verified',
          valueTextAdditionalClass: classes.successText,
          placeholderAdditionalClass: classes.errorText,
          spaceBetween: true,
        },
        {
          title: 'Insurance ID',
          condition: Boolean(profile?.insuranceId),
          placeholder: 'N/A',
          value: profile?.insuranceId as string,
          spaceBetween: true,
        },
        {
          title: 'Insurance Name',
          condition: Boolean(profile?.insuranceName),
          placeholder: 'N/A',
          value: profile?.insuranceName as string,
          columnDirection: true,
        },
      ]

  return (
    <>
      {insuranceInfoBlocks.map(
        ({
          value,
          condition,
          getData,
          placeholder,
          title,
          valueTextAdditionalClass,
          placeholderAdditionalClass,
          spaceBetween,
          columnDirection,
          expandable,
          details,
        }) => (
          <Box key={title}>
            <Accordion
              className={clsx(classes.accordion, {
                [classes.withoutPointerEvents]: !expandable || !withExpansion,
              })}
              expanded={withExpansion && expandable && expanded[title]}
              onChange={
                expandable && withExpansion
                  ? () =>
                      setExpanded((expanded) => ({
                        ...expanded,
                        [title]: !expanded[title],
                      }))
                  : noop
              }
            >
              <AccordionSummary
                expandIcon={
                  expandable && withExpansion ? <ExpandMoreIcon /> : null
                }
                classes={{
                  root: classes.accordionSummary,
                  content: clsx({
                    [classes.spaceBetween]: spaceBetween,
                    [classes.columnDirection]: columnDirection,
                  }),
                }}
              >
                <Typography variant="body2" className={classes.text}>
                  {title}
                </Typography>

                {getData ? (
                  <Typography
                    fontWeight={getData().value ? 500 : 400}
                    variant="body2"
                    className={clsx(classes.text, ...getData().classes)}
                  >
                    {getData().value}
                  </Typography>
                ) : (
                  <>
                    {condition ? (
                      <Typography
                        fontWeight={500}
                        variant="body2"
                        className={clsx(classes.text, valueTextAdditionalClass)}
                      >
                        {value}
                      </Typography>
                    ) : (
                      <Typography
                        variant="body2"
                        className={clsx(
                          classes.text,
                          classes.hintText,
                          placeholderAdditionalClass
                        )}
                      >
                        {placeholder}
                      </Typography>
                    )}
                  </>
                )}
              </AccordionSummary>

              {details && (
                <AccordionDetails className={classes.accordionDetails}>
                  {details}
                </AccordionDetails>
              )}
            </Accordion>
          </Box>
        )
      )}
    </>
  )
}

export default DetailedInsuranceInfoCard
