import { useCallback, useEffect, useState, useMemo } from 'react'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import AuthLayout from '../../components/layouts/AuthLayout'
import FooterButtons from '../../components/FooterButtons'
import {
  getAppointmentProductId,
  getCopayInfoSelector,
  getFetchingPaymentMethods,
  getPayForAppointmentSubmitting,
  getPaymentMethods,
  getSelectedPaymentMethod,
  useProductById,
} from '../../store/appointment/selectors'
import appointmentActions from '../../store/appointment/actions'
import OverlayItem from '../../components/common/OverlayItem'
import { isLoading } from '../../utils/types'
import useLoadingFailure from '../../hooks/useLoadingFailure'
import { RoutePath } from '../../routes'
import { PaymentMethod } from '../../store/appointment/models'
import useLoadingSuccess from '../../hooks/useLoadingSuccess'
import PaymentMethodsList from '../../components/appointmentCreation/PaymentMethodsList'
import ThemedButton from '../../components/common/ThemedButton'
import { IndicatedPaymentMethod } from '../../store/appointment/types'
import gtag from '../../utils/gtag'
import { extractErrorMessage } from '../../utils/errors'
import useLoadingFailureAlert from '../../hooks/useLoadingFailureAlert'
import { useIsLabOrderingFlow } from '../../hooks/labOrdering/useIsLabOrderingFlow'
import { useLabOrderData } from '../../hooks/labOrdering/useLabOrderData'
import { payForLabOrderAction } from '../../store/testOrdering'
import { useLabOrderDraftCreate } from '../../hooks/labOrdering/useLabOrderDraftCreate'
import {
  getConsultationExistence,
  getIsEmployerProgram,
  getPayingForLabOrder,
} from '../../store/testOrdering/selectors'
import { LabOrderPayInsuranceOption } from '../../components/labOrdering/LabOrderPayInsuranceOption'
import { isNil } from 'lodash'
import { getUserProfile } from '../../store/auth/selectors'

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
  },
  paymentsListWrapper: {
    maxHeight: 200,
    overflow: 'auto',
  },
  addNewBtn: {
    justifyContent: 'flex-start',
    width: 122,
  },
}))

const ChoosePaymentCard = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const history = useHistory()

  const [paymentMethodId, setPaymentMethodId] = useState<
    PaymentMethod['collectlyId'] | null
  >(null)

  const isLabOrderingFlow = useIsLabOrderingFlow()

  const isEmployerProgram = useSelector(getIsEmployerProgram)

  const consultationExistence = useSelector(getConsultationExistence)

  const { order } = useLabOrderData()

  const { labOrderDraft } = useLabOrderDraftCreate()

  const payingForLabOrder = useSelector(getPayingForLabOrder)

  const fetchingPaymentMethods = useSelector(getFetchingPaymentMethods)
  const isFetchingPaymentMethods = isLoading(fetchingPaymentMethods.state)

  const paymentMethods = useSelector(getPaymentMethods)

  const appointmentProductId = useSelector(getAppointmentProductId)
  const { product: consultationProduct } = useProductById(appointmentProductId)

  const selectedPaymentMethod = useSelector(getSelectedPaymentMethod)
  const isCashPayment = selectedPaymentMethod === IndicatedPaymentMethod.cash

  const payForAppointmentSubmitting = useSelector(
    getPayForAppointmentSubmitting
  )

  const copayInfo = useSelector(getCopayInfoSelector)

  const profile = useSelector(getUserProfile)

  useEffect(() => {
    dispatch(appointmentActions.getPaymentMethods.request())
  }, [])

  useLoadingSuccess(fetchingPaymentMethods, () => {
    if (!paymentMethods.length && isLabOrderingFlow) {
      history.replace(RoutePath.addPaymentMethod)
    }
  })

  const handleBackClick = useCallback(() => {
    history.goBack()
  }, [history])

  const handleSavePaymentMethodForConsultation = useCallback(() => {
    if ((isCashPayment || copayInfo) && !isLabOrderingFlow) {
      dispatch(appointmentActions.payForAppointment.request())
    } else {
      history.push(RoutePath.createAppointment)
    }
  }, [dispatch, history, isCashPayment, copayInfo, isLabOrderingFlow])

  const handleSavePaymentMethodForLabOrder = useCallback(() => {
    dispatch(
      payForLabOrderAction({
        customerOrderId: labOrderDraft!.id,
        paymentMethodId: paymentMethodId!,
      })
    )
  }, [dispatch, paymentMethodId, labOrderDraft])

  const triggerSubmit = useCallback(() => {
    dispatch(
      appointmentActions.selectPaymentMethod({
        paymentMethod: paymentMethodId!,
      })
    )

    if (consultationExistence) {
      handleSavePaymentMethodForConsultation()
    } else {
      handleSavePaymentMethodForLabOrder()
    }
  }, [
    dispatch,
    paymentMethodId,
    consultationExistence,
    handleSavePaymentMethodForLabOrder,
    handleSavePaymentMethodForConsultation,
  ])

  useLoadingSuccess(fetchingPaymentMethods, () => {
    if (!paymentMethods.length) {
      history.replace(RoutePath.addPaymentMethod)
    } else {
      setPaymentMethodId(paymentMethods[0].collectlyId)
    }
  })

  useLoadingFailure(fetchingPaymentMethods, () => {
    history.replace(RoutePath.choosePaymentCard)
  })

  useLoadingSuccess(payForAppointmentSubmitting, () => {
    history.replace(RoutePath.paymentSuccessful)
  })

  useLoadingFailure(payForAppointmentSubmitting, ({ error }) => {
    gtag('event', 'webView_attach_payment_method_failed', {
      message: extractErrorMessage(error),
    })
  })

  useLoadingFailureAlert(payForAppointmentSubmitting)

  useLoadingSuccess(payingForLabOrder, () => {
    history.replace(RoutePath.labOrderPaymentSuccessful)
  })

  useLoadingFailureAlert(payingForLabOrder)

  const pageIsLoading = useMemo(
    () =>
      isLoading(payForAppointmentSubmitting.state) ||
      isLoading(payingForLabOrder.state),
    [payForAppointmentSubmitting, payingForLabOrder]
  )

  const employerProgramPrice = isCashPayment
    ? order?.employerProgramPrice
    : isNil(copayInfo?.paymentAmount)
    ? !isNil(profile.employeeInsuranceInfo?.deductible) ||
      !isNil(profile.employeeInsuranceInfo?.copay)
      ? (profile.employeeInsuranceInfo.copay || 0) +
        (profile.employeeInsuranceInfo.deductible || 0)
      : order?.employerProgramPrice
    : copayInfo!.paymentAmount

  const amountToPay = useMemo(() => {
    if (isLabOrderingFlow && order && !consultationExistence) {
      return isEmployerProgram ? employerProgramPrice : order.price
    } else if (isCashPayment || copayInfo?.paymentAmount) {
      return isCashPayment
        ? consultationProduct?.priceDecimal
        : copayInfo?.paymentAmount
    }

    return 0
  }, [
    copayInfo,
    consultationProduct,
    order,
    isLabOrderingFlow,
    consultationExistence,
    isCashPayment,
    employerProgramPrice,
  ])

  return (
    <AuthLayout
      title={
        isNil(amountToPay) ? 'Add Card Information' : `To Pay: $${amountToPay}`
      }
      contentClass={classes.content}
      bottomActions={
        <FooterButtons
          nextButtonLabel="Next"
          backButtonLabel="Back"
          disableNext={!paymentMethodId || pageIsLoading}
          disableBack={pageIsLoading}
          loadingNext={pageIsLoading}
          onNextButtonClick={triggerSubmit}
          onBackButtonClick={handleBackClick}
        />
      }
    >
      {isFetchingPaymentMethods && (
        <OverlayItem>
          <CircularProgress />
        </OverlayItem>
      )}
      <Stack spacing={1}>
        <Typography color="primary.dark">
          {isCashPayment
            ? 'You have chosen the Self-pay option.'
            : 'Credit card information is required for any deductible, copay or cancellation fees that may apply.'}
        </Typography>

        <Stack spacing={1}>
          <Stack direction="column" spacing={2} width="100%">
            <Box className={classes.paymentsListWrapper}>
              <PaymentMethodsList
                disabled={pageIsLoading}
                selectedPaymentId={paymentMethodId}
                payments={paymentMethods}
                onSelectPayment={(paymentId) => setPaymentMethodId(paymentId)}
              />
            </Box>

            <ThemedButton
              disabled={pageIsLoading}
              size="small"
              variant="text"
              className={classes.addNewBtn}
              onClick={() => history.push(RoutePath.addPaymentMethod)}
            >
              Add new card
            </ThemedButton>
          </Stack>

          {isCashPayment && isLabOrderingFlow && (
            <Box>
              <LabOrderPayInsuranceOption />
            </Box>
          )}
        </Stack>
      </Stack>
    </AuthLayout>
  )
}

export default ChoosePaymentCard
