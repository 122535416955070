import { Box, Skeleton, Stack, Typography } from '@mui/material'
import { useLabOrderData } from '../../hooks/labOrdering/useLabOrderData'
import { useLocation } from 'react-router-dom'
import { RoutePath } from '../../routes'
import {
  getIsEmployerProgram,
  getLabOrderBiomarkers,
} from '../../store/testOrdering/selectors'
import { useSelector } from 'react-redux'

const PRODUCT_IMG_SIZE = 60

export default function LabOrderPreviewCard() {
  const { order } = useLabOrderData()
  const location = useLocation()

  const isEmployerProgram = useSelector(getIsEmployerProgram)

  const biomarkers = useSelector(getLabOrderBiomarkers)

  if (location.pathname.includes(RoutePath.labOrderInfo)) {
    return null
  }

  const isFloating = [
    RoutePath.createMedicalAccount,
    RoutePath.checkEmail,
  ].some((path) => location.pathname.includes(path))

  return (
    <Stack
      sx={{
        py: 1.5,
        px: 2.5,
        border: (theme) => `1px solid ${theme.palette.divider}`,
        borderRadius: 5,
        margin: '25px 20px 0',
        background: (theme) => theme.palette.background.paper,
        ...(isFloating && {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1,
        }),
      }}
      direction="row"
      spacing={1.5}
    >
      {!order ? (
        <>
          <Box>
            <Skeleton
              variant="circular"
              width={PRODUCT_IMG_SIZE}
              height={PRODUCT_IMG_SIZE}
            />
          </Box>
          <Stack flex={1} spacing={1}>
            <Typography variant="h6">
              <Skeleton variant="text" sx={{ fontSize: 'inherit' }} />
            </Typography>
            <Skeleton variant="rounded" width={80} sx={{ fontSize: '1rem' }} />
          </Stack>
        </>
      ) : (
        <>
          {order?.image && (
            <Box>
              <Box
                component="img"
                src={order.image.imageUrl}
                sx={{
                  width: PRODUCT_IMG_SIZE,
                  height: PRODUCT_IMG_SIZE,
                  borderRadius: '50%',
                  objectFit: 'cover',
                }}
                alt=""
              />
            </Box>
          )}
          <Stack flex={1} spacing={1}>
            <Box>
              <Typography
                variant="h6"
                color="primary.dark"
                sx={{
                  fontFamily: 'NeurialGrotesk',
                  fontWeight: 700,
                  lineHeight: 1.2,
                  letterSpacing: '-0.5px',
                }}
              >
                {isEmployerProgram ? 'Employer Program' : order.name}
              </Typography>
            </Box>
            <Box>
              <Box
                sx={{
                  borderRadius: 5,
                  backgroundColor: '#CDDFFA',
                  color: '#7694D3',
                  py: 0.5,
                  px: 1,
                  display: 'inline-block',
                }}
              >
                <Typography variant="body2">
                  {biomarkers?.length} Biomarkers
                </Typography>
              </Box>
            </Box>
          </Stack>
        </>
      )}
    </Stack>
  )
}
