import React, { useCallback, useEffect } from 'react'
import { Theme, Typography, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import InfoIcon from '@mui/icons-material/Info'
import { useHistory } from 'react-router-dom'

import clinicsActions from 'src/store/clinics/actions'
import {
  getAppointmentClinic,
  getSourceWidgetType,
  getIsTelemedicineClinicFlag,
  getAppointment,
} from '../../../store/appointment/selectors'
import gtag from '../../../utils/gtag'
import AuthLayout from '../../../components/layouts/AuthLayout'
import { getAbsoluteUrl } from '../../../utils/general'

import appCheckInImage from '../../../assets/images/appCheckIn.png'
import ThemedButton from '../../../components/common/ThemedButton'
import FooterControls from '../../../components/FooterControls'
import { RoutePath } from '../../../routes'
import { useIsLabOrderingFlow } from '../../../hooks/labOrdering/useIsLabOrderingFlow'
import {
  getConsultationExistence,
  getFetchingLabOrderReceipt,
  getLabOrderReceipt,
} from '../../../store/testOrdering/selectors'
import { isLoading } from '../../../utils/types'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(1, 0, 2),
      overflow: 'auto',
    },
    text: {
      color: theme.palette.primary.dark,
      lineHeight: 1.5,
    },
    imageContainer: {
      borderRadius: 14,
      overflow: 'hidden',
      margin: theme.spacing(3, 0),
    },
    image: {
      maxWidth: '100%',
      width: '100%',
    },
    email: {
      fontWeight: 700,
    },
    list: {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '1rem',
        margin: 0,
      },
      paddingLeft: '2rem',
    },
  }),
  { name: 'AppointmentConfirmed' }
)

const AppointmentConfirmed: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const history = useHistory()

  const isLabOrderingFlow = useIsLabOrderingFlow()

  const consultationExistence = useSelector(getConsultationExistence)

  const selectedClinicInfo = useSelector(getAppointmentClinic)

  const appointment = useSelector(getAppointment)
  const isTelemedicineClinic = useSelector(getIsTelemedicineClinicFlag)
  const sourceWidgetType = useSelector(getSourceWidgetType)

  const labOrderReceipt = useSelector(getLabOrderReceipt)
  const fetchingLabOrderReceipt = useSelector(getFetchingLabOrderReceipt)

  const handleReceiptDownload = () => {
    const downloadLink = document.createElement('a')
    downloadLink.style.display = 'none'
    downloadLink.href = labOrderReceipt!

    downloadLink.click()
    downloadLink.remove()
  }

  useEffect(() => {
    if (
      (!selectedClinicInfo || isEmpty(selectedClinicInfo)) &&
      selectedClinicInfo?.id
    ) {
      dispatch(
        clinicsActions.getClinicById.request({
          selectedClinicId: selectedClinicInfo?.id,
          widgetType: sourceWidgetType,
        })
      )
    }
  }, [selectedClinicInfo?.id])

  const handleGoNext = useCallback(() => {
    gtag('event', 'webView_appt_confirmed_download_click')

    window.open('https://onelink.to/vqjesa', '_blank')
  }, [])

  useEffect(() => {
    gtag('event', 'apptstart_complete', {
      symptoms: true,
    })

    if (appointment.symptomatic) {
      gtag('event', 'apptstart_symptoms_complete')
    }
  }, [])

  return (
    <AuthLayout
      contentClass={classes.root}
      bottomActions={
        <FooterControls>
          <ThemedButton onClick={handleGoNext}>Download App</ThemedButton>
          {consultationExistence && (
            <ThemedButton
              variant="outlined"
              onClick={() =>
                history.push(RoutePath.confirmedAppointmentDetails)
              }
            >
              View Appt Details
            </ThemedButton>
          )}

          {isLabOrderingFlow && labOrderReceipt && (
            <ThemedButton
              loading={isLoading(fetchingLabOrderReceipt.state)}
              variant="outlined"
              onClick={handleReceiptDownload}
            >
              View Receipt
            </ThemedButton>
          )}
        </FooterControls>
      }
    >
      <Typography className={classes.text} variant="h5" gutterBottom>
        <b>
          {isTelemedicineClinic || isLabOrderingFlow
            ? `Download the Kyla App to get your ${
                consultationExistence ? 'consultation' : 'lab results'
              }.`
            : 'Save your time in clinics with Kyla App'}
        </b>
      </Typography>
      <Box>
        {(isTelemedicineClinic ||
          (isLabOrderingFlow && consultationExistence)) && (
          <Box display="flex" alignItems="center">
            <Box mr={2}>
              <InfoIcon color="primary" />
            </Box>
            <Typography className={classes.text}>
              Online consultations are available through the app only.
            </Typography>
          </Box>
        )}

        <Box className={classes.imageContainer}>
          <img
            className={classes.image}
            src={getAbsoluteUrl(appCheckInImage)}
            alt=""
          />
        </Box>

        <Typography className={classes.text} variant="h5" gutterBottom>
          <b>Benefits for you</b>
        </Typography>
        <ul className={classes.list}>
          {[
            "Doctor's recommendations",
            'Receive test results',
            'eCheck-in before every appointment',
          ].map((item) => (
            <li key={item}>
              <Typography className={classes.text} variant="body1">
                {item}
              </Typography>
            </li>
          ))}
        </ul>
      </Box>
    </AuthLayout>
  )
}

export default AppointmentConfirmed
