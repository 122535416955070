import React from 'react'
import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers'
import { noop } from 'lodash'
import { BasePickerProps } from '@mui/x-date-pickers/internals'

import ThemedTextField from './ThemedTextField'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      borderRadius: '10px',
      border: '2px solid #BFD4F0',
      // padding: theme.spacing(1.25, 0, 1.25, 2),
      '&:hover': {
        borderColor: `${theme.palette.primary.dark} !important`,
      },
      backgroundColor: theme.palette.primary.light,
      '& input': {
        fontFamily: 'NeurialGrotesk',
        fontSize: '0.875rem',
        lineHeight: '1.3',
        color: theme.palette.primary.dark,
      },
      '& input:disabled': {
        color: 'rgba(0, 0, 0, 0.38)',
      },
      '& label': {
        fontFamily: 'NeurialGrotesk',
        fontSize: '0.875rem',
        opacity: 0.5,
        color: theme.palette.primary.dark,
      },
    },
  }),
  { name: 'DatePicker' }
)

interface DatePickerProps
  extends Omit<BasePickerProps<any, any>, 'onChange' | 'value'> {
  error?: boolean
  onChange?(): void
  maxDate?: any
  value?: BasePickerProps<any, any>
}

const ThemedDatePicker = React.forwardRef<any, DatePickerProps>(
  (
    { error = false, value = '', onChange = noop, ...props },
    ref: React.Ref<any>
  ) => {
    const classes = useStyles()

    return (
      <MuiDatePicker
        disableFuture
        className={classes.root}
        InputProps={{
          disableUnderline: true,
        }}
        inputFormat="MM/dd/yyyy"
        inputRef={ref}
        renderInput={(params: any) => (
          <ThemedTextField
            {...params}
            error={error}
            margin="none"
            placeholder="Date of birth"
          />
        )}
        {...{ onChange, value }}
        {...props}
      />
    )
  }
)

export default ThemedDatePicker
