import React, { useCallback, useState } from 'react'
import {
  Box,
  Typography,
  Button,
  Divider,
  Link,
  CircularProgress,
  Grid,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'

import Avatar from 'src/assets/icons/Avatar.svg'
import { getAbsoluteUrl, SUPPORT_PHONE_NUMBER } from '../../../utils/general'
import WelcomeImage from '../../../assets/images/WelcomeImage.jpg'
import ThemedTextField from '../../../components/common/ThemedTextField'
import { RoutePath } from '../../../routes'
import authActions from '../../../store/auth/actions'
import {
  getCheckEmailLoading,
  getIsDeletedAccount,
  getIsBlacklistedAccount,
} from '../../../store/auth/selectors'
import {
  isFailure,
  isLoading,
  isSuccess,
  LoadingContext,
} from '../../../utils/types'
import { useLoadingChange } from '../../../hooks/useLoadingChange'
import ConfirmationDialog, {
  ActionsDirection,
} from '../../../components/dialogs/ConfirmationDialog'
import gtag from '../../../utils/gtag'
import { DELETED_ACCOUNT_MESSAGE } from '../../../utils/consts/auth'
import parsePhoneNumber from 'libphonenumber-js'
import BlacklistedAccountMessage from '../../../components/signUp/BlacklistedAccountMessage'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flex: 1,
      flexDirection: 'column',
      position: 'relative',
      paddingTop: 250,
    },
    imageContainer: {
      height: 490,
      width: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundImage: `linear-gradient(180deg, rgba(196, 196, 196, 0) 30%, rgb(0 78 184 / 75%) 70%),
       url(${getAbsoluteUrl(WelcomeImage)})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      borderRadius: '40px 40px 0 0',
    },
    features: {
      zIndex: 2,
      display: 'flex',
    },
    content: {
      padding: theme.spacing(3.5, 2.5),
      borderRadius: '40px',
      backgroundColor: '#fff',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      zIndex: 2,
      marginTop: theme.spacing(4),
      justifyContent: 'space-between',
    },
    text: {
      fontFamily: 'NeurialGrotesk',
    },
    title: {
      fontWeight: 700,
      fontSize: '1.375rem',
      letterSpacing: '-0.5px',
      color: theme.palette.primary.dark,
      lineHeight: 1.5,
      marginBottom: theme.spacing(4),
    },
    emailInput: {
      width: '100%',
      borderRadius: '10px',
      marginBottom: theme.spacing(2),
    },
    button: {
      borderRadius: '12px',
      height: 56,
      fontSize: '1rem',
      fontFamily: 'NeurialGrotesk',
      fontWeight: 700,
    },
    avatar: {
      flex: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    featuresTitles: {
      flex: 3,
      display: 'flex',
      flexDirection: 'column',
    },
    featureTitle: {
      fontWeight: 700,
      fontSize: '1.375rem',
      letterSpacing: '-0.5px',
      lineHeight: 1.5,
      color: '#fff',
      margin: theme.spacing(1, 0),
    },
    divider: {
      backgroundColor: '#fff',
    },
    haveAccText: {
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '20px',
      color: theme.palette.primary.dark,
    },
    loginButton: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
    errorText: {
      color: theme.palette.error.main,
      fontSize: '0.875rem',
    },
    errorWrapper: {
      marginTop: theme.spacing(0.5),
      maxHeight: 100,
      overflow: 'auto',
    },
    dialogRoot: {
      zIndex: 2,
    },
  }),
  { name: 'CheckEmail' }
)

const CheckEmail: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const [showDeletedAccountDialog, setShowDeletedAccountDialog] = useState(
    false
  )

  const [error, setError] = useState('')

  const checkingEmail = useSelector(getCheckEmailLoading)
  const isCheckingEmail = isLoading(checkingEmail.state)

  const isDeletedAccount = useSelector(getIsDeletedAccount)
  const isBlacklistedAccount = useSelector(getIsBlacklistedAccount)

  const { register, errors, handleSubmit, watch } = useForm<{ email: string }>({
    mode: 'onChange',
    shouldFocusError: false,
  })
  const { email } = watch()

  const handleEmailCheckingLoadingChange = useCallback(
    (newLoading: LoadingContext) => {
      if (isSuccess(newLoading.state)) {
        if (isDeletedAccount || isBlacklistedAccount) {
          setShowDeletedAccountDialog(true)
        } else {
          history.push(RoutePath.createAccount)
        }

        dispatch(authActions.clearAccountDeletedState())
      }
      if (isFailure(newLoading.state)) {
        setError(newLoading.message as string)

        gtag('event', 'webView_check_email_failed', {
          message: newLoading.message,
        })
      }
    },
    [isDeletedAccount, isBlacklistedAccount]
  )

  useLoadingChange(handleEmailCheckingLoadingChange, checkingEmail)

  const handleCheckEmail = useCallback(({ email }) => {
    dispatch(authActions.checkEmail.request({ email }))
  }, [])

  const handleReactivateAccount = () => {
    history.push(RoutePath.reactivateAccount)
  }

  const handleCallSupport = () => {
    window.open(
      parsePhoneNumber(SUPPORT_PHONE_NUMBER)?.getURI() || '',
      '_blank'
    )
  }

  return (
    <Box display="flex" className={classes.root}>
      <Box className={classes.imageContainer} />
      <Box className={classes.features}>
        <Box className={classes.avatar}>
          <img src={getAbsoluteUrl(Avatar)} alt="kyla-avatar" />
        </Box>
        <Box className={classes.featuresTitles}>
          <Typography className={clsx(classes.text, classes.featureTitle)}>
            Measure
          </Typography>
          <Divider className={classes.divider} />
          <Typography className={clsx(classes.text, classes.featureTitle)}>
            Improve
          </Typography>
          <Divider className={classes.divider} />
          <Typography className={clsx(classes.text, classes.featureTitle)}>
            Live Longer
          </Typography>
          <Divider className={classes.divider} />
        </Box>
      </Box>
      <Box className={classes.content}>
        <Box>
          <Typography className={clsx(classes.text, classes.title)}>
            Create an account
          </Typography>

          <ThemedTextField
            disabled={isCheckingEmail}
            name="email"
            autoComplete="off"
            inputRef={register({
              required: true,
              pattern: {
                value: /^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,4}$/i,
                message: 'Invalid email address',
              },
            })}
            classes={{ input: classes.emailInput }}
            color="primary"
            placeholder="Email"
            error={Boolean(errors.email)}
          />

          <Button
            fullWidth
            color="primary"
            className={classes.button}
            variant="contained"
            onClick={handleSubmit(handleCheckEmail)}
            disabled={isCheckingEmail}
          >
            {isCheckingEmail ? <CircularProgress size={24} /> : 'Continue'}
          </Button>

          {error && (
            <Grid className={classes.errorWrapper}>
              <Typography className={clsx(classes.text, classes.errorText)}>
                {error}
              </Typography>
            </Grid>
          )}
        </Box>
        <Box>
          <Typography
            align="center"
            className={clsx(classes.text, classes.haveAccText)}
          >
            Already have an account?{' '}
            <Link
              className={classes.loginButton}
              onClick={() => history.push(RoutePath.signIn)}
            >
              Login
            </Link>
          </Typography>
        </Box>
      </Box>
      <ConfirmationDialog
        actionsDirection={
          isBlacklistedAccount ? ActionsDirection.column : ActionsDirection.row
        }
        classes={{ root: classes.dialogRoot }}
        open={showDeletedAccountDialog}
        confirmButtonLabel={
          isBlacklistedAccount
            ? parsePhoneNumber(SUPPORT_PHONE_NUMBER)?.formatNational()
            : undefined
        }
        content={
          isBlacklistedAccount ? (
            <BlacklistedAccountMessage email={email} />
          ) : (
            DELETED_ACCOUNT_MESSAGE
          )
        }
        onClose={() => setShowDeletedAccountDialog(false)}
        onConfirm={
          isBlacklistedAccount ? handleCallSupport : handleReactivateAccount
        }
      />
    </Box>
  )
}

export default CheckEmail
