import React, { useEffect, useMemo, useCallback } from 'react'
import { Stack, Typography, CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'

import AuthLayout from 'src/components/layouts/AuthLayout'
import { useLabOrderData } from '../../hooks/labOrdering/useLabOrderData'
import OverlayItem from '../../components/common/OverlayItem'
import { LabProduct } from '../../store/testOrdering/models'
import { BIOMETRICS_CODE } from '../../utils/general'
import FooterButtons from '../../components/FooterButtons'
import queryString from 'query-string'
import { decorateUrlGtag } from '../../utils/decorateUrlGtag'
import { config } from '../../utils/config'
import { LabOrderingPatientInfo } from '../../utils/labOrdering'
import { setLabPatientInfoStore } from '../../utils/labPatientInfoStore'
import { trackLabOrderNowClick } from '../../utils/analytics'

const useStyles = makeStyles(
  (theme) => ({
    content: {
      overflow: 'auto',
      marginBottom: theme.spacing(2),
    },
    text: {
      color: theme.palette.primary.dark,
      fontFamily: 'SF-Pro',
      lineHeight: '24px',
      fontWeight: 400,
    },
    boldText: {
      fontWeight: 700,
    },
    title: {
      color: theme.palette.primary.dark,
      fontFamily: 'Arial',
      letterSpacing: '-0.5px',
    },
    priceTitle: {
      fontSize: '32px',
      lineHeight: 1,
    },
    image: {
      height: 240,
      margin: theme.spacing(3, 0, 2),
      borderRadius: '6px',
    },
    list: {
      paddingLeft: theme.spacing(3),
      overflowY: 'auto',
      listStyle: 'disc',
    },
  }),
  { name: 'LabOrderInformation' }
)

export type LabOrderInformationProps = {
  labOrderId?: string
  patientInfo?: LabOrderingPatientInfo
}

const LabOrderInformation: React.FC<LabOrderInformationProps> = ({
  labOrderId,
  patientInfo,
}) => {
  const history = useHistory()

  const classes = useStyles()

  const { order, getLabOrderData } = useLabOrderData()

  useEffect(() => {
    getLabOrderData(Number(labOrderId))
  }, [getLabOrderData])

  const handleProceed = useCallback(() => {
    if (patientInfo) {
      setLabPatientInfoStore(patientInfo)
    }

    const url = queryString.stringifyUrl({
      url: `${config.server.kylaWpHost}/confirm-lab-ordering`,
      query: {
        labOrderId,
      },
    })

    trackLabOrderNowClick({
      labId: Number(labOrderId),
      price: Number(order?.price),
    })

    window.open(decorateUrlGtag(url), '_blank')

    // history.push(RoutePath.confirmLabOrderRoute)
  }, [history, patientInfo])

  const biomarkers = useMemo(() => {
    const biometricProperty = order?.properties.find(
      ({ code }) => code === BIOMETRICS_CODE
    ) as LabProduct['properties'][0]

    return biometricProperty ? biometricProperty.value.name.split(',') : []
  }, [JSON.stringify(order)])

  return (
    <>
      {order ? (
        <AuthLayout
          title={
            <>
              <Typography
                className={clsx(
                  classes.title,
                  classes.boldText,
                  classes.priceTitle
                )}
                fontSize={20}
              >
                {`${order.name}`}
              </Typography>
              <Typography
                className={clsx(classes.title, classes.boldText)}
                fontSize={16}
              >
                {`($${Number(order.price)} or Pay With Insurance)`}
              </Typography>
            </>
          }
          contentProps={{ className: classes.content }}
          topActions={
            <FooterButtons
              nextButtonLabel="Order Now"
              nextOnly
              onNextButtonClick={handleProceed}
            />
          }
        >
          <Stack spacing={3} flex={1}>
            <Stack pt={2} justifyContent="center">
              <img
                src={order.image.imageUrl}
                className={classes.image}
                alt=""
              />
            </Stack>

            <Stack spacing={0.5}>
              <Typography
                className={clsx(classes.text, classes.boldText)}
                fontSize={18}
              >
                Included Tests:
              </Typography>
              <ul className={classes.list}>
                {biomarkers.map((biomarker) => (
                  <li className={classes.text} key={biomarker}>
                    {biomarker}
                  </li>
                ))}
              </ul>
            </Stack>
          </Stack>
        </AuthLayout>
      ) : (
        <OverlayItem>
          <CircularProgress />
        </OverlayItem>
      )}
    </>
  )
}

export default LabOrderInformation
