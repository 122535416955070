import React from 'react'
import { Stack, Typography, Box, Avatar } from '@mui/material'
import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles'

import { LabProduct } from '../../store/testOrdering/models'
import { BIOMETRICS_CODE } from '../../utils/general'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(9, 4, 4),
      height: '100%',
    },
    text: {
      fontFamily: 'NeurialGrotesk',
      color: theme.palette.primary.dark,
      lineHeight: 'normal',
    },
    title: {
      fontWeight: 500,
      fontSize: '18px',
    },
    biomarkersWrapper: {
      width: 'fit-content',
      padding: theme.spacing(0.5, 0.75),
      borderRadius: 11,
      background: 'rgba(0, 163, 255, 0.3)',
    },
    smallText: {
      lineHeight: 1,
      fontSize: '13px',
      color: theme.palette.primary.main,
      opacity: 0.6,
    },
  }),
  { name: 'ConfirmLabOrder' }
)

type LabOrderCardProps = {
  order: LabProduct
}

const LabOrderCard: React.FC<LabOrderCardProps> = ({ order }) => {
  const classes = useStyles()

  const biometricProperty = order.properties.find(
    ({ code }) => code === BIOMETRICS_CODE
  ) as LabProduct['properties'][0]

  const biomarkersCount = biometricProperty.value.name.split(',').length

  return (
    <Stack direction="row" spacing={1.5}>
      <Avatar
        alt={order.name}
        src={order.image.imageUrl}
        sx={{ width: 64, height: 64 }}
      />

      <Stack spacing={0.75}>
        <Typography className={clsx(classes.text, classes.title)}>
          {order.name}
        </Typography>

        <Typography className={classes.text}>
          {`Insurance coverage may vary. Self-pay is $${Number(order.price)}`}
        </Typography>

        <Box
          display="flex"
          alignItems="center"
          className={classes.biomarkersWrapper}
        >
          <Typography className={clsx(classes.text, classes.smallText)}>
            {`${biomarkersCount} Biomarkers`}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  )
}

export default LabOrderCard
