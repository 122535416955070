import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { ActionType } from 'typesafe-actions'

// eslint-disable-next-line import/no-cycle,import/named
import { serverAxios } from '../../utils/api/axios'
import { getInsuranceData } from './utils'
import { getUserProfile } from '../auth/selectors'
import { insuranceActions } from './actions'
// eslint-disable-next-line import/no-cycle
import { extractErrorMessage } from '../../utils/errors'
import { AxiosError } from 'axios'

function* getCompanies({
  payload: { query },
}: ActionType<typeof insuranceActions.getCompanies.request>): SagaIterator {
  try {
    const response = yield call(serverAxios.get, 'api/v1/eligibility/payers', {
      params: { limit: 20, search: query },
    })

    yield put(
      insuranceActions.getCompanies.success({ companies: response.content })
    )
  } catch (error) {
    const axiosError = error as AxiosError

    yield put(
      insuranceActions.getCompanies.failure({
        message: extractErrorMessage(error, 'Failed to get companies list'),
        error: axiosError,
      })
    )
  }
}

function* verifyInsurance({
  payload: { insuranceValues, images, isEmployerProgram },
}: ActionType<typeof insuranceActions.verifyInsurance.request>): SagaIterator {
  try {
    const generalInfo = yield select(getUserProfile)

    const formData = getInsuranceData(
      insuranceValues,
      generalInfo,
      images,
      isEmployerProgram
    )

    const response = yield call(
      serverAxios.put,
      '/api/v3/patient/profile/insurance',
      formData
    )

    yield put(
      insuranceActions.verifyInsurance.success({
        profile: response.profile,
      })
    )
  } catch (error) {
    const axiosError = error as AxiosError
    yield put(
      insuranceActions.verifyInsurance.failure({
        message: extractErrorMessage(error, 'Failed to update insurance'),
        error: axiosError,
      })
    )
  }
}

function* getPatientOfficeAlly(): SagaIterator {
  try {
    const response = yield call(
      serverAxios.get,
      '/mobile/api/v1/patient/profile/office-ally-info'
    )

    yield put(
      insuranceActions.getPatientOfficeAlly.success({
        officeAllyInfo: response,
      })
    )
  } catch (error) {
    const axiosError = error as AxiosError

    yield put(
      insuranceActions.getPatientOfficeAlly.failure({
        message: extractErrorMessage(
          error,
          'Failed to get patient office ally info'
        ),
        error: axiosError,
      })
    )
  }
}

export const insuranceSagas = [
  takeLatest(insuranceActions.getCompanies.request, getCompanies),
  takeLatest(insuranceActions.verifyInsurance.request, verifyInsurance),
  takeLatest(
    insuranceActions.getPatientOfficeAlly.request,
    getPatientOfficeAlly
  ),
]
