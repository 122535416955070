export interface InsuranceCompany {
  name: string
  id: string
  insuranceProvider: string | null
}

export type OfficeAllyInfo = {
  copayUrgentCare: number
  copayTelemedicine: number
  deductibleIndividual: number
  deductibleFamily: number
  outOfPocketRemainingFamily: number
  outOfPocketRemainingIndividual: number
  coinsuranceUrgentCare: number
  coinsuranceTelemedicine: number
  urgentCare: boolean
  primaryCare: boolean
  inNetwork: boolean
}

export enum InsuranceStatus {
  Limited = 'Limited',
  Verified = 'Verified',
  NotVerified = 'NotVerified',
}
