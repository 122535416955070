import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'

import {
  isFailure,
  isLoading,
  isSuccess,
  LoadingContext,
} from 'src/utils/types'
import FooterButtons from '../../../components/FooterButtons'
import ResetPasswordForm, {
  ResetPasswordFormValues,
} from '../../../components/forms/ResetPasswordForm'
import authActions from '../../../store/auth/actions'
import {
  getGeneralInfo,
  getReactivateAccountVerificationCode,
  getSignInLoading,
  getUpdatedPassword,
  getUpdatingPassword,
  getUserAnalyticId,
} from '../../../store/auth/selectors'
import { useLoadingChange } from '../../../hooks/useLoadingChange'
import { RoutePath } from '../../../routes'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(9, 4, 4),
      height: '100%',
      position: 'relative',
    },
    content: {
      flex: 1,
    },
    text: {
      fontFamily: 'NeurialGrotesk',
    },
    title: {
      fontWeight: 700,
      fontSize: '1.375rem',
      letterSpacing: '-0.5px',
      color: theme.palette.primary.dark,
      lineHeight: 1.5,
      marginBottom: theme.spacing(2),
    },
    errorText: {
      color: theme.palette.error.main,
      fontSize: '0.875rem',
    },
    actions: {
      padding: theme.spacing(4, 0),
    },
  }),
  { name: 'CreateNewPassword' }
)

export type CreateNewPasswordRef = {
  submit(): Promise<Blob | null>
}

const CreateNewPassword: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const formRef = useRef<CreateNewPasswordRef>(null)

  const [generalValidationError, setGeneralValidationError] = useState('')

  const updatingPassword = useSelector(getUpdatingPassword)
  const isUpdatingPassword = isLoading(updatingPassword.state)
  const reactivationCode = useSelector(getReactivateAccountVerificationCode)
  const userAnalyticId = useSelector(getUserAnalyticId)
  const signInLoading = useSelector(getSignInLoading)
  const signInIsLoading = isLoading(signInLoading.state)

  const generalInfo = useSelector(getGeneralInfo)
  const updatedPassword = useSelector(getUpdatedPassword)

  const handleGoNext = useCallback(() => {
    formRef?.current?.submit()
    setGeneralValidationError('')
  }, [formRef, setGeneralValidationError])

  const handleFormSubmit = useCallback(
    (password: ResetPasswordFormValues['password']) => {
      dispatch(
        authActions.updatePassword.request({
          password,
          email: generalInfo.email,
          reactivationCode: reactivationCode as string,
        })
      )
    },
    [generalInfo, reactivationCode]
  )

  useLoadingChange((nextSubmitting) => {
    if (isSuccess(nextSubmitting.state)) {
      dispatch(
        authActions.signIn.request({
          password: updatedPassword!,
          email: generalInfo?.email,
        })
      )
    }
  }, updatingPassword)

  const handleSuccessfulProceed = useCallback(() => {
    history.push(RoutePath.addressInfo)
  }, [history])

  const handleLoadingChange = useCallback(
    (newLoading: LoadingContext) => {
      if (isSuccess(newLoading.state)) {
        if (userAnalyticId) {
          window.analytics?.identify?.(userAnalyticId)
        }

        // TODO: discuss with Dima (duplicate of "CreateMedicalAccount" screen)
        handleSuccessfulProceed()
      }
      if (isFailure(newLoading.state)) {
        setGeneralValidationError(newLoading.message as string)

        dispatch(authActions.clearAccountDeletedState())
      }
    },
    [
      dispatch,
      history,
      setGeneralValidationError,
      userAnalyticId,
      handleSuccessfulProceed,
    ]
  )

  useLoadingChange(handleLoadingChange, signInLoading)

  const formIsLoading = useMemo(() => signInIsLoading || isUpdatingPassword, [
    signInIsLoading,
    isUpdatingPassword,
  ])

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={classes.root}
    >
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        className={classes.content}
      >
        <Typography className={clsx(classes.text, classes.title)}>
          Create a new password
        </Typography>
        <Grid>
          <ResetPasswordForm
            ref={formRef}
            onSubmit={handleFormSubmit}
            disabled={formIsLoading}
          />

          <Typography
            align="center"
            className={clsx(classes.text, classes.errorText)}
          >
            {generalValidationError}
          </Typography>
        </Grid>
      </Grid>
      <Grid>
        <FooterButtons
          nextOnly
          classes={{ root: classes.actions }}
          nextButtonLabel="Save password"
          onNextButtonClick={handleGoNext}
          loadingNext={formIsLoading}
        />
      </Grid>
    </Grid>
  )
}

export default CreateNewPassword
