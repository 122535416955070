import { createAction, createAsyncAction } from 'typesafe-actions'
import { AxiosError } from 'axios'
import { Moment } from 'moment'

import { InsuranceCompany, OfficeAllyInfo } from './models'
import { ServerErrorResponse } from '../../utils/api/types'
import { UsersProfile } from '../auth/models'

export const insuranceActions = {
  getCompanies: createAsyncAction(
    'insurance/GET_ALL',
    'insurance/GET_ALL_SUCCESS',
    'insurance/GET_ALL_FAILURE'
  )<
    { query: string },
    { companies: InsuranceCompany[] },
    { message: string; error: AxiosError<ServerErrorResponse> }
  >(),
  clearCompanies: createAction('insurance/CLEAR_COMPANIES')<void>(),
  verifyInsurance: createAsyncAction(
    'insurance/VERIFY',
    'insurance/VERIFY_SUCCESS',
    'insurance/VERIFY_FAILURE'
  )<
    {
      insuranceValues: {
        insuranceName: string
        insuranceId: string
        firstName: string
        lastName: string
        dob: Moment
      }
      images: {
        front: File | null
        back: File | null
      }
      isEmployerProgram?: boolean
    },
    { profile: UsersProfile },
    { message: string; error?: AxiosError<ServerErrorResponse> }
  >(),
  getPatientOfficeAlly: createAsyncAction(
    'insurance/GET_PATIENT_OFFICE_ALLY',
    'insurance/GET_PATIENT_OFFICE_ALLY_SUCCESS',
    'insurance/GET_PATIENT_OFFICE_ALLY_FAILURE'
  )<
    void,
    { officeAllyInfo: OfficeAllyInfo },
    { message: string; error: AxiosError<ServerErrorResponse> }
  >(),
}
