import React, { useCallback, useImperativeHandle, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Grid, IconButton, InputAdornment, Theme } from '@mui/material'
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles';

import ThemedTextField from '../common/ThemedTextField'

const useStyles = makeStyles(
  (theme: Theme) => ({
    formInput: {
      width: '100%',
      borderRadius: '10px',
      marginTop: theme.spacing(1.25),
    },
    iconButtonRoot: {
      color: theme.palette.primary.dark,
    },
  }),
  { name: 'SignInForm' }
)
export type SignInFormValues = {
  email: string
  password: string
}

interface SignInFormProps {
  onSubmit(data: SignInFormValues): void
  disabled: boolean
}

const SignInForm = React.forwardRef<any, SignInFormProps>(
  ({ onSubmit, disabled }, ref: React.Ref<any>) => {
    const classes = useStyles()

    const [showPassword, setShowPassword] = useState(false)

    const { register, handleSubmit, errors } = useForm<SignInFormValues>({
      mode: 'onChange',
      shouldFocusError: false,
    })

    const handleNewAccountSubmit = useCallback(
      (values: SignInFormValues) => {
        onSubmit(values)
      },
      [onSubmit]
    )

    useImperativeHandle(ref, () => ({
      submit: handleSubmit(handleNewAccountSubmit),
    }))

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword)
    }

    return (
      <Grid>
        <ThemedTextField
          name="email"
          disabled={disabled}
          autoComplete="off"
          inputRef={register({
            required: true,
            pattern: {
              value: /^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,4}$/i,
              message: 'Invalid email address',
            },
          })}
          classes={{ input: classes.formInput }}
          color="primary"
          placeholder="Email"
          error={Boolean(errors.email)}
        />

        <ThemedTextField
          name="password"
          disabled={disabled}
          autoComplete="off"
          inputRef={register({ required: true })}
          classes={{ input: classes.formInput }}
          type={showPassword ? 'text' : 'password'}
          color="primary"
          placeholder="Password"
          error={Boolean(errors.password)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                disabled={disabled}
                classes={{ root: classes.iconButtonRoot }}
                onClick={handleClickShowPassword}
                size="large">
                {showPassword ? (
                  <VisibilityOutlined />
                ) : (
                  <VisibilityOffOutlined />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </Grid>
    );
  }
)

export default SignInForm
