import React from 'react'
import {
  MenuItem,
  Select,
  Theme,
  Typography,
  FormControl,
  SelectProps,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import ThemedTextField from './ThemedTextField'

const useStyles = makeStyles(
  (theme: Theme) => ({
    icon: {
      display: 'none',
    },
    menuItem: {
      fontFamily: 'NeurialGrotesk',
      fontSize: '0.875rem',
      color: theme.palette.primary.dark,
    },
    placeholder: {
      fontFamily: 'NeurialGrotesk',
      fontSize: '0.875rem',
      lineHeight: 1.3,
      color: theme.palette.primary.dark,
      opacity: 0.5,
    },
    select: {
      backgroundColor: theme.palette.primary.light,
      borderRadius: '10px',
      color: theme.palette.primary.dark,
      border: '2px solid #BFD4F0',
      '&:hover': {
        borderColor: theme.palette.primary.dark,
      },
      '&:focus': {
        borderRadius: '10px',
        backgroundColor: theme.palette.primary.light,
      },
    },
  }),
  { name: 'ThemedSelect' }
)

type ThemedSelectProps = SelectProps & {
  values: Array<{ name: string; id: string }>
  error?: boolean
  value?: string
  placeholder: string
  disabled?: boolean
}

const ThemedSelect = React.forwardRef<any, ThemedSelectProps>(
  (
    { values, error, placeholder, value = '', disabled = false, ...props },
    ref
  ) => {
    const classes = useStyles()

    return (
      <FormControl error>
        <Select
          ref={ref}
          displayEmpty
          disabled={disabled}
          classes={{ icon: classes.icon, select: classes.select }}
          input={<ThemedTextField forSelect error={error} />}
          value={value}
          {...props}
          {...(value
            ? {}
            : {
                renderValue: () => (
                  <Typography className={classes.placeholder}>
                    {placeholder}
                  </Typography>
                ),
              })}
        >
          {values.map(({ name, id }) => (
            <MenuItem className={classes.menuItem} value={id} key={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }
)

export default ThemedSelect
