import React, { useCallback, useRef, useState } from 'react'
import { Grid, Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

import authActions from 'src/store/auth/actions'
import FooterButtons from '../../../components/FooterButtons'
import {
  isFailure,
  isLoading,
  isSuccess,
  LoadingContext,
} from '../../../utils/types'
import { useLoadingChange } from '../../../hooks/useLoadingChange'
import { forgotPasswordLoading } from '../../../store/auth/selectors'
import ForgotPasswordForm, {
  ForgotPasswordFormValues,
} from '../../../components/forms/ForgotPasswordForm'
import ConfirmationDialog from '../../../components/dialogs/ConfirmationDialog'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(9, 4, 4),
      height: '100%',
      position: 'relative',
    },
    text: {
      fontFamily: 'NeurialGrotesk',
      color: theme.palette.primary.dark,
      fontWeight: 700,
    },
    title: {
      fontSize: '1.375rem',
      letterSpacing: '-0.5px',
      lineHeight: 1.5,
      marginBottom: theme.spacing(5),
    },
    errorText: {
      color: theme.palette.error.main,
      fontSize: '0.875rem',
    },
    subTitle: {
      fontSize: '1rem',
    },
  }),
  { name: 'ForgotPassword' }
)

const ForgotPassword: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const forgotPasswordLoadingObj = useSelector(forgotPasswordLoading)
  const forgotPasswordIsLoading = isLoading(forgotPasswordLoadingObj.state)

  const [generalValidationError, setGeneralValidationError] = useState('')
  const [showConfirmation, setShowConfirmation] = useState(false)

  const handleLoadingChange = useCallback(
    (newLoading: LoadingContext) => {
      if (isSuccess(newLoading.state)) {
        setShowConfirmation(true)
      }
      if (isFailure(newLoading.state)) {
        setGeneralValidationError(newLoading.message as string)
      }
    },
    [history, setGeneralValidationError, setShowConfirmation]
  )

  useLoadingChange(handleLoadingChange, forgotPasswordLoadingObj)

  const formRef = useRef<any>(null)

  const handleGoNext = useCallback(() => {
    formRef?.current?.submit()
    setGeneralValidationError('')
  }, [formRef, setGeneralValidationError])

  const handleGoBack = useCallback(() => {
    history.goBack()
  }, [history])

  const handleFormSubmit = useCallback(
    (data: ForgotPasswordFormValues) => {
      dispatch(authActions.forgotPassword.request({ ...data }))
    },
    [dispatch]
  )

  return (
    <Grid container className={classes.root}>
      <Grid container direction="column" justifyContent="space-between">
        <Grid>
          <Typography className={clsx(classes.text, classes.title)}>
            Forgot password?
          </Typography>
          <Typography className={clsx(classes.text, classes.subTitle)}>
            Enter the email address associated with your account
          </Typography>
          <ForgotPasswordForm
            ref={formRef}
            onSubmit={handleFormSubmit}
            disabled={forgotPasswordIsLoading || showConfirmation}
          />

          <Typography
            align="center"
            className={clsx(classes.text, classes.errorText)}
          >
            {generalValidationError}
          </Typography>
        </Grid>
        <Grid>
          <FooterButtons
            nextButtonLabel="Submit"
            backButtonLabel="Back"
            onNextButtonClick={handleGoNext}
            onBackButtonClick={handleGoBack}
            loadingNext={forgotPasswordIsLoading}
            disableBack={forgotPasswordIsLoading || showConfirmation}
            disableNext={showConfirmation}
          />
        </Grid>
      </Grid>
      <ConfirmationDialog
        cancelOnly
        cancelButtonLabel="Close"
        open={showConfirmation}
        title="Password Reset"
        content="Please check your email for further instructions"
        onClose={handleGoBack}
      />
    </Grid>
  )
}

export default ForgotPassword
