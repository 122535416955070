import { Stack, styled, Typography } from '@mui/material'
import MaskedInput from 'react-text-mask'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import AuthLayout from '../../components/layouts/AuthLayout'
import FooterButtons from '../../components/FooterButtons'
import { COMPANY_ID_MASK } from '../../utils/general'
import ThemedTextField from '../../components/common/ThemedTextField'
import {
  getJoinCorporateProgramInfo,
  getJoiningCorporateProgram,
} from '../../store/testOrdering/selectors'
import { joinCorporateProgram } from '../../store/testOrdering'
import { getUserProfile } from '../../store/auth/selectors'
import { isLoading } from '../../utils/types'
import useLoadingFailureAlert from '../../hooks/useLoadingFailureAlert'
import { RoutePath } from '../../routes'

const StyledTypography = styled(Typography)(() => ({
  fontFamily: 'NeurialGrotesk',
}))

const CorporateProgram = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [companyId, setCompanyId] = useState('')

  const joiningCorporateProgram = useSelector(getJoiningCorporateProgram)
  const joinCorporateProgramInfo = useSelector(getJoinCorporateProgramInfo)

  const profile = useSelector(getUserProfile)

  const handleJoinCorporateProgram = () => {
    dispatch(
      joinCorporateProgram({
        patientId: profile.id,
        companyId: Number(companyId),
      })
    )
  }

  const handleProceedNext = () => {
    history.push(RoutePath.addressInfo)
  }

  useLoadingFailureAlert(joiningCorporateProgram)

  const isBusy = isLoading(joiningCorporateProgram.state)

  return (
    <AuthLayout
      title="Corporate Program"
      bottomActions={
        <FooterButtons
          nextOnly
          nextButtonLabel="Continue"
          disableNext={
            joinCorporateProgramInfo
              ? false
              : !Number(companyId) || companyId.length !== 6
          }
          loadingNext={isBusy}
          onNextButtonClick={
            joinCorporateProgramInfo
              ? handleProceedNext
              : handleJoinCorporateProgram
          }
          onBackButtonClick={handleProceedNext}
        />
      }
    >
      {joinCorporateProgramInfo ? (
        <Stack spacing={1}>
          <StyledTypography color="primary.dark" fontWeight={500}>
            You have been successfully added to the company:
          </StyledTypography>

          <Stack>
            <StyledTypography
              color="primary.dark"
              fontWeight={700}
              fontSize="1.25rem"
            >
              {joinCorporateProgramInfo.company?.name}
            </StyledTypography>

            <StyledTypography color="action.disabled">
              {`(Company ID ${joinCorporateProgramInfo.company?.id})`}
            </StyledTypography>
          </Stack>
        </Stack>
      ) : (
        <Stack spacing={5}>
          <StyledTypography color="primary.dark">
            We partner with employers to help you achieve your health and
            wellness goals. Enter your 6-digit Company ID to unlock your
            personalized health journey!
          </StyledTypography>

          <Stack spacing={1}>
            <StyledTypography color="primary.dark" fontWeight={700}>
              Company ID
            </StyledTypography>

            <MaskedInput
              mask={COMPANY_ID_MASK}
              render={(ref, props) => (
                <ThemedTextField
                  fullWidth
                  disabled={isBusy}
                  ref={ref}
                  autoComplete="off"
                  placeholder="Input 6-digit Company ID"
                  {...props}
                />
              )}
              value={companyId}
              onChange={(e) => setCompanyId(e.target.value)}
            />
          </Stack>
        </Stack>
      )}
    </AuthLayout>
  )
}

export default CorporateProgram
