export enum RoutePath {
  actionUrgentCareHome = '/action-urgent-carre-home',
  inClinicHome = '/in-clinic-home',
  selectedClinicHome = '/selected-clinic-home',
  appointmentTime = '/appointment-time',
  appointmentReasons = '/reason',
  atHomeCare = '/at-home-care',
  atHomeCareFailure = '/at-home-care-failure',
  confirmAppointment = '/confirm-appointment',
  confirmedAppointmentDetails = '/confirmed-appointment-details',
  virtualVisit = '/virtual-visit-home',
  createAccount = '/create-new-account',
  checkEmail = '/check-email',
  verifyEmail = '/verify-email',
  createPassword = '/create-password',
  addSignature = '/add-signature',
  idCard = '/id-card',
  reactivateAccount = '/reactivate-account',
  createNewPassword = '/create-new-password',
  appointmentConfirmed = '/appointment-confirmed',
  signIn = '/sign-in',
  forgotPassword = '/forgot-password',
  resetPassword = '/reset-password',
  resetPasswordConfirm = '/reset-password-confirm',
  verificationOptions = '/verification-options',
  verificationPage = '/verification-page',
  noAppointmentAvailable = '/no-appointment-available',
  symptomsCheckerResults = '/symptoms-checker-results',
  checkChronoAccount = '/check-chrono-account',
  checkCronoAccountFailure = '/check-chrono-account-failure',
  choosePaymentMethod = '/choose-payment-method',
  choosePaymentCard = '/choose-payment-card',
  addPaymentMethod = '/add-payment-method',
  insuranceInfo = '/insurance-info',
  insuranceAutoVerificationFailed = '/insurance-auto-verification-failed',
  createMedicalAccount = '/create-medical-account',
  insuranceManualVerificationSuccess = '/insurance-manual-verification-success',
  insuranceManualVerificationFailure = '/insurance-manual-verification-failure',
  createAppointment = '/create-appointment-loading',
  paymentSuccessful = '/payment-successful',
  checkPendingAppt = '/pending-appt-check',
  addressInfo = '/add-address-information',
  copayPayment = '/copay-payment',
  insuranceDetailedInfo = '/insurance-detailed-info',
  outdatedTos = '/outdated-tos',
  confirmLabOrderRoute = '/confirm-lab-order-route',
  chooseLabOrderPaymentMethod = '/choose-lab-order-payment-method',
  chooseConsultationExistence = '/choose-consultation-existence',
  labOrderPaymentSuccessful = '/lab-order-payment-successful',
  labOrderInfo = '/lab-order-info',
  corporateProgramCompanyId = '/corporate-program-company-id',
}

export const actionUrgentCareMainFlow = [
  RoutePath.actionUrgentCareHome,
  RoutePath.appointmentTime,
  RoutePath.appointmentReasons,
]

export const inClinicMainFlow = [
  RoutePath.inClinicHome,
  RoutePath.appointmentReasons,
]

export const specificClinicRoutes = [
  RoutePath.selectedClinicHome,
  RoutePath.appointmentReasons,
  RoutePath.noAppointmentAvailable,
]

export const virtualVisitRoutes = [
  RoutePath.virtualVisit,
  RoutePath.symptomsCheckerResults,
  RoutePath.appointmentReasons,
]

export const atHomeCareRoutes = [
  RoutePath.atHomeCare,
  RoutePath.appointmentTime,
  RoutePath.appointmentReasons,
  RoutePath.atHomeCareFailure,
]

export const confirmAppointmentRoutes = [
  RoutePath.confirmAppointment,
  RoutePath.checkEmail,
  RoutePath.createAccount,
  RoutePath.addressInfo,
  RoutePath.verifyEmail,
  RoutePath.createPassword,
  RoutePath.choosePaymentMethod,
  RoutePath.copayPayment,
  RoutePath.insuranceDetailedInfo,
  RoutePath.choosePaymentCard,
  RoutePath.addPaymentMethod,
  RoutePath.insuranceInfo,
  RoutePath.insuranceManualVerificationSuccess,
  RoutePath.insuranceManualVerificationFailure,
  RoutePath.insuranceAutoVerificationFailed,
  RoutePath.addSignature,
  RoutePath.idCard,
  RoutePath.checkChronoAccount,
  RoutePath.checkCronoAccountFailure,
  RoutePath.reactivateAccount,
  RoutePath.createNewPassword,
  RoutePath.verificationOptions,
  RoutePath.verificationPage,
  RoutePath.appointmentConfirmed,
  RoutePath.signIn,
  RoutePath.outdatedTos,
  RoutePath.forgotPassword,
  RoutePath.createMedicalAccount,
  RoutePath.createAppointment,
  RoutePath.checkPendingAppt,
  RoutePath.confirmedAppointmentDetails,
  RoutePath.labOrderInfo,
]

export const resetPasswordRoutes = [
  RoutePath.resetPassword,
  RoutePath.resetPasswordConfirm,
]

export const confirmLabOrderRoutes = [
  RoutePath.confirmLabOrderRoute,
  RoutePath.chooseLabOrderPaymentMethod,
  RoutePath.chooseConsultationExistence,
  RoutePath.labOrderPaymentSuccessful,
  RoutePath.appointmentReasons,
]

export const labOrderInfoRoutes = [RoutePath.labOrderInfo]
